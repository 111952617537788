import React, { FC } from 'react';
import interpolateContent from '../../../helpers/interpolate-content';
import { ProductCardLabelsType } from '../../../models/product-card-labels-type';
import { ProductLineItemType } from '../../../models/product-line-item-type';
import SpecialPricingEnum from '../../../models/special-pricing-enum';
import { SpecialPricingType } from '../../../models/special-pricing-type';
import DiagonalStrikethrough from '../../atoms/diagonal-strikethrough/diagonal-strikethrough';
import useIsLoggedIn from '../../providers/user/hooks/use-is-logged-in';

interface Props {
  readonly basketItem: ProductLineItemType | any;
  readonly horizontal?: boolean;
  readonly labels: ProductCardLabelsType;
  readonly specialPrice?: SpecialPricingType | any;
}

const ProductPricing: FC<Props> = ({
  basketItem,
  horizontal,
  labels,
  specialPrice,
}: Props) => {
  const isLoggedIn = useIsLoggedIn();

  if (isLoggedIn && !specialPrice && horizontal) {
    return <span style={{ height: '1.6rem' }} />;
  }

  if (isLoggedIn && !specialPrice && !horizontal) {
    return <span style={{ height: '4.8rem' }} />;
  }

  return (
    <div
      className={`${
        horizontal ? '' : 'my-2.5'
      } flex justify-between items-center`}
    >
      <div className="flex space-x-4">
        {basketItem.listPriceFormatted &&
          basketItem.listPrice > specialPrice?.price &&
          !horizontal && (
            <div className="text-grey-600">
              <DiagonalStrikethrough color="grey-600">
                <div className="text-lg leading-none">
                  {basketItem.listPriceFormatted}
                </div>
              </DiagonalStrikethrough>

              <span className="text-sm">{labels.listPriceLabel}</span>
            </div>
          )}

        {(specialPrice?.priceFormatted || basketItem.buyPriceFormatted) &&
          !horizontal && (
            <div>
              <div className="text-3xl font-bold text-french-blue leading-none">
                {specialPrice?.priceFormatted || basketItem.buyPriceFormatted}
              </div>

              <span className="text-sm">{labels.buyPriceLabel}</span>
            </div>
          )}

        {(specialPrice?.priceFormatted || basketItem.buyPriceFormatted) &&
          horizontal && (
            <div className="leading-none flex space-x-1">
              <span className="flex-shrink-0 text-sm text-french-blue">
                {labels.buyPriceLabel}
              </span>

              <span className="text-sm text-french-blue">
                {specialPrice?.priceFormatted || basketItem.buyPriceFormatted}
              </span>
            </div>
          )}
      </div>

      <div className="flex space-x-1">
        {specialPrice?.priceType === SpecialPricingEnum.ListWithDiscount && (
          <span className="bg-black block rounded-lg py-1 px-2 text-white text-xs">
            {interpolateContent(
              labels.savePercentageLabel,
              specialPrice?.discount
            )}
          </span>
        )}

        {specialPrice?.priceType === SpecialPricingEnum.SpecialNettPrice && (
          <span className="bg-black block rounded-lg py-1 px-2 text-white text-xs">
            {labels.specialPriceLabel}
          </span>
        )}

        {specialPrice?.priceType === SpecialPricingEnum.PromoProductPrice && (
          <span className="bg-black block rounded-lg py-1 px-2 text-white text-xs">
            {labels.onSaleLabel}
          </span>
        )}
      </div>
    </div>
  );
};

ProductPricing.defaultProps = {
  horizontal: false,
  specialPrice: null,
};

export default ProductPricing;
