/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Axios, { CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  FC,
  FocusEvent,
  MouseEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useIsTouchDevice from '../../../hooks/use-is-touch-device/use-is-touch-device';
import useNextImageLoader from '../../../hooks/use-next-image-loader/use-next-image-loader';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { CategoryStructureType } from '../../../models/category-structure-type';
import { ContentPageHeaderProductNavType } from '../../../models/content-page-header-product-nav-type';
import { ContentPageHeaderType } from '../../../models/content-page-header-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { HeaderSearchResultType } from '../../../models/header-search-result-type';
import LocalStorageKeysEnum from '../../../models/local-storage-keys-enum';
import categoryStructure from '../../../services/content-api/endpoints/category/structure';
import searchHeader from '../../../services/search-api/endpoints/search/header';
import IcomoonIconButton from '../../atoms/icomoon-icon-button/icomoon-icon-button';
import Layout from '../../layout/layout';
import HeaderAccountDropdown from '../../molecules/header-account-dropdown/header-account-dropdown';
import HeaderIconButton from '../../molecules/header-icon-button/header-icon-button';
import HeaderMobileMenu from '../../molecules/header-mobile-menu/header-mobile-menu';
import HeaderNavigation from '../../molecules/header-navigation/header-navigation';
import HeaderSearchDropdown from '../../molecules/header-search-dropdown/header-search-dropdown';
import HeaderSearchTool from '../../molecules/header-search-tool/header-search-tool';
import HeaderSiteMessage from '../../molecules/header-site-message/header-site-message';
import { BasketStateContext } from '../../providers/basket/basket.provider';
import { NavigationStateContext } from '../../providers/navigation/navigation.provider';
import close from '../../providers/overlay/close.overlay.actions';
import open from '../../providers/overlay/open.overlay.actions';
import {
  OverlayDispatchContext,
  OverlayStateContext,
} from '../../providers/overlay/overlay.provider';

interface Props extends ContentPageHeaderType {
  readonly className?: string;
  readonly isAuthenticatedPage: boolean;
}

const Header: FC<Props> = (props: Props) => {
  const {
    accountIcon,
    accountLabel,
    basketPageLink,
    className,
    contactPageLink,
    isAuthenticatedPage,
    logo,
    navigation,
    productSearchData,
    searchPageLink,
  } = props;

  const router = useRouter();
  const [catalogId, setCatalogId] = useState<string>();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [productNavigationData, setProductNavigationData] =
    useState<ContentPageHeaderProductNavType>();
  const [searchRequestCancelToken, setSearchRequestCancelToken] =
    useState<CancelTokenSource>();
  const [showSiteMessage, setShowSiteMessage] = useState<boolean>(false);
  const overlayDispatch = useContext(OverlayDispatchContext);
  const overlayState = useContext(OverlayStateContext);
  const isTouchDevice = useIsTouchDevice();
  const accountButtonRef = useRef(null);
  const basketState = useContext(BasketStateContext);
  const navigationState = useContext(NavigationStateContext);
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  useEffect(() => {
    const handle = () => {
      if (overlayState.isHeaderSearch) {
        overlayDispatch(close());
      }
    };

    router.events.on('routeChangeComplete', handle);

    return () => {
      router.events.off('routeChangeComplete', handle);
    };
  }, [overlayState.isHeaderSearch]);

  useEffect(() => {
    const siteMessageDismissedDate = localStorage.getItem(
      LocalStorageKeysEnum.SiteMessageDismissedDate
    );

    if (!siteMessageDismissedDate) {
      setShowSiteMessage(true);

      return;
    }

    setShowSiteMessage(
      dayjs(siteMessageDismissedDate).add(1, 'd').diff(new Date()) < 0
    );

    categoryStructure().then((response: CategoryStructureType) =>
      setCatalogId(response.catalogId)
    );
  }, []);

  useEffect(() => {
    if (!navigationState?.productNavigationData) {
      return;
    }

    setProductNavigationData(navigationState?.productNavigationData);
  }, [navigationState?.productNavigationData]);

  const openMobileMenu = (): void => {
    if (navigationState) {
      overlayDispatch(
        open(
          <HeaderMobileMenu
            {...navigation}
            contactPageLink={contactPageLink.link}
            productNavigationData={productNavigationData}
          />
        )
      );
    }

    pushToDataLayer(GtmEventNameEnum.MobileNavOpen);
  };

  const openAccountDropdown = (event: FocusEvent | MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();

    overlayDispatch(
      open(
        <HeaderAccountDropdown
          {...props}
          isAuthenticatedPage={isAuthenticatedPage}
          menuRect={accountButtonRef.current.getBoundingClientRect()}
        />,
        true
      )
    );
  };

  const [dataSearchResponse, setDataSearchResponse] = useState<any>({
    brandResults: [],
    categoryResults: [],
    featuredResults: [],
    results: [],
  });
  const [selectedCategory, setSelectedCategory] = useState('');
  const [updatedSearchTerm, setUpdatedSearchTerm] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');

  useEffect(() => {
    if (searchInputValue.length > 0) {
      overlayDispatch(
        open(
          <HeaderSearchDropdown
            {...dataSearchResponse}
            labels={productSearchData.headerSearchLabels}
            selectedCategory={selectedCategory}
            searchPageLink={searchPageLink}
            searchTerm={updatedSearchTerm}
            isSearching={isSearching}
          />,
          true,
          true
        )
      );
    }
  }, [searchInputValue, dataSearchResponse]);

  const onHeaderSearchChange = async ({
    searchTerm: updatedSearchTerm,
    selectedCategory,
  }): Promise<void> => {
    setSearchInputValue(updatedSearchTerm);
    if (searchRequestCancelToken) {
      searchRequestCancelToken.cancel();
    }

    setSearchRequestCancelToken(Axios.CancelToken.source());
    setSelectedCategory(selectedCategory);
    setUpdatedSearchTerm(updatedSearchTerm);
    searchHeader({
      catalogId,
      filters: selectedCategory.categoryId
        ? { categoryFilter: selectedCategory.categoryId }
        : {},
      query: updatedSearchTerm,
    }).then((response: HeaderSearchResultType) => {
      setIsSearching(false);
      setSearchRequestCancelToken(null);
      setDataSearchResponse(response);
    });
  };

  const onHeaderSearchSubmit = ({
    searchTerm: updatedSearchTerm,
    selectedCategory,
  }) => {
    overlayDispatch(close());

    router.push({
      pathname: searchPageLink.url,
      query: {
        ...(updatedSearchTerm ? { query: updatedSearchTerm } : {}),
        ...(selectedCategory?.categoryId
          ? { category: selectedCategory.categoryId }
          : {}),
      },
    });
  };

  return (
    <>
      <div className="mb-header-mobile lg:mb-header" />

      <header>
        <nav
          id="main-nav"
          className={`${className} fixed top-0 w-full z-40`}
          onClick={() => overlayDispatch(close())}
        >
          <div className="bg-white border-b border-grey-50 py-2">
            <Layout>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="block lg:hidden mr-2">
                    <IcomoonIconButton
                      color="french-blue"
                      name="menu"
                      onClick={() => openMobileMenu()}
                    />
                  </div>

                  <Link href="/" passHref>
                    <a
                      className="flex items-center"
                      style={{ width: '160px' }}
                      href="replace"
                      onClick={() => overlayDispatch(close())}
                      aria-label="Logo home"
                    >
                      {logo && (
                        <Image
                          alt={logo.altText}
                          height={48}
                          loader={useNextImageLoader}
                          src={logo.url}
                          width={170}
                        />
                      )}
                    </a>
                  </Link>
                </div>

                <div className="hidden lg:block flex-grow ml-6 xl:ml-12 mr-4 xl:mr-9">
                  <HeaderSearchTool
                    {...productSearchData}
                    isSearching={isSearching}
                    onSearchStatusChange={(searching: boolean) =>
                      setIsSearching(searching)
                    }
                    onSearchChange={onHeaderSearchChange}
                    onSearchSubmit={onHeaderSearchSubmit}
                  />
                </div>

                <div className="flex">
                  <HeaderIconButton
                    className="hidden lg:block"
                    icon={contactPageLink?.icon}
                    label={contactPageLink?.link.name}
                    link={contactPageLink?.link}
                    onClick={() => {
                      overlayDispatch(close());

                      pushToDataLayer(GtmEventNameEnum.NavContact);
                    }}
                  />

                  <div ref={accountButtonRef}>
                    <HeaderIconButton
                      icon={accountIcon}
                      id="account-header-navigation-list"
                      label={accountLabel}
                      onClick={(event: MouseEvent) =>
                        isTouchDevice && openAccountDropdown(event)
                      }
                      onFocus={(event: FocusEvent) =>
                        !isTouchDevice && openAccountDropdown(event)
                      }
                      onMouseOver={(event: MouseEvent) =>
                        !isTouchDevice && openAccountDropdown(event)
                      }
                    />
                  </div>

                  <HeaderIconButton
                    className={basketState?.isLoading ? 'animate-pulse' : ''}
                    icon={
                      basketState?.itemCount
                        ? basketPageLink?.icon
                        : 'basket-outline'
                    }
                    isLabelBold={!!basketState?.itemCount}
                    itemCount={basketState?.itemCount}
                    label={basketState?.total || '£0.00'}
                    link={basketPageLink?.link}
                    onClick={() => {
                      overlayDispatch(close());

                      pushToDataLayer(GtmEventNameEnum.NavBasket);
                    }}
                  />
                </div>
              </div>
            </Layout>
          </div>

          <HeaderNavigation
            navigationData={navigation?.navigationData}
            productNavigationData={productNavigationData}
          />

          <div className="bg-white block lg:hidden py-3 shadow-sm">
            <Layout>
              <HeaderSearchTool
                {...productSearchData}
                isSearching={isSearching}
                onSearchStatusChange={(searching: boolean) =>
                  setIsSearching(searching)
                }
                onSearchChange={onHeaderSearchChange}
                onSearchSubmit={onHeaderSearchSubmit}
              />
            </Layout>
          </div>
        </nav>
      </header>

      {navigation?.siteMessageContent && (
        <HeaderSiteMessage siteMessageContent={navigation?.siteMessageContent} />
      )}
    </>
  );
};

Header.defaultProps = {
  className: '',
};

export default Header;
