import { useEffect, useState } from 'react';
import useWindowSize from '../use-window-size/use-window-size';
import { ImageType } from '../../models/image-type';
import getTailwindConfig from '../../helpers/get-tailwind-config';

export default function useImage(image: ImageType): string {
  const { width: windowWidth } = useWindowSize();
  const [selectedImage, setSelectedImage] = useState<string>(image?.desktopUrl);
  const { screens } = getTailwindConfig().theme;

  useEffect(() => {
    let responsiveImage = image?.desktopUrl;

    if (windowWidth <= parseInt(screens.md, 10) && image?.tabletUrl) {
      responsiveImage = image?.tabletUrl;
    }

    if (windowWidth <= parseInt(screens.sm, 10) && image?.mobileUrl) {
      responsiveImage = image?.mobileUrl;
    }

    setSelectedImage(responsiveImage);
  }, [image, screens, windowWidth]);

  return selectedImage;
}
