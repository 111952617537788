import React, { FC, ReactNode } from 'react';

interface Props {
  readonly className?: string;
  readonly children: ReactNode;
  readonly label?: string;
}

const TableData: FC<Props> = ({ className, children, label }: Props) => (
  <td className={`${className} px-5 py-2 md:py-3`}>
    <div className="flex flex-col">
      {label && (
        <span className="lg:hidden font-bold text-sm text-grey-600">
          {label}
        </span>
      )}
      {children}
    </div>
  </td>
);

TableData.defaultProps = {
  className: '',
  label: null,
};

export default TableData;
