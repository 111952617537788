import dynamic from 'next/dynamic';
import { ComponentType } from 'react';
import ComponentEnum from '../../models/component-enum';

const Components: Record<ComponentEnum, ComponentType> = {
  [ComponentEnum.AccountBenefits]: dynamic(
    () => import('./account-benefits/account-benefits')
  ),
  [ComponentEnum.AccountNavigation]: dynamic(
    () => import('./account-navigation/account-navigation')
  ),
  [ComponentEnum.AccountSubNavigation]: dynamic(
    () => import('./account-sub-navigation/account-sub-navigation')
  ),
  [ComponentEnum.AppDownloadCallToAction]: dynamic(
    () => import('./mobile-app-panel/mobile-app-panel')
  ),
  [ComponentEnum.BasketDetails]: dynamic(
    () => import('./basket-details/basket-details')
  ),
  [ComponentEnum.BrandListing]: dynamic(
    () => import('./brand-listing/brand-listing')
  ),
  [ComponentEnum.Breadcrumb]: dynamic(() => import('./breadcrumb/breadcrumb')),
  [ComponentEnum.BulkOrdering]: dynamic(
    () => import('./bulk-ordering/bulk-ordering')
  ),
  [ComponentEnum.CallToActionRowWithIcons]: dynamic(
    () => import('./highlighted-services/highlighted-services')
  ),
  [ComponentEnum.CategoriesCarousel]: dynamic(
    () => import('./categories-carousel/categories-carousel')
  ),
  [ComponentEnum.CategoryLandingHeading]: dynamic(
    () => import('./category-landing-heading/category-landing-heading')
  ),
  [ComponentEnum.Checkout]: dynamic(() => import('./checkout/checkout')),
  [ComponentEnum.ChildCategoryPanels]: dynamic(
    () => import('./child-category-panels/child-category-panels')
  ),
  [ComponentEnum.ContactDetails]: dynamic(
    () => import('./contact-details/contact-details')
  ),
  [ComponentEnum.CookiePolicy]: dynamic(
    () => import('../molecules/cookie-policy/cookie-policy')
  ),
  [ComponentEnum.CreditAccountDetails]: dynamic(
    () => import('./credit-account-details/credit-account-details')
  ),
  [ComponentEnum.DeliveryAddresses]: dynamic(
    () => import('./delivery-addresses/delivery-addresses')
  ),
  [ComponentEnum.DespatchNoteRequest]: dynamic(
    () => import('./despatch-note-request/despatch-note-request')
  ),
  [ComponentEnum.DiscountStructure]: dynamic(
    () => import('./discount-structure/discount-structure')
  ),
  [ComponentEnum.DoublePanels]: dynamic(
    () => import('./double-panels/double-panels')
  ),
  [ComponentEnum.ExtraInformationSignposts]: dynamic(
    () => import('./extra-information-signposts/extra-information-signposts')
  ),
  [ComponentEnum.FavouriteBaskets]: dynamic(
    () => import('./favourite-baskets/favourite-baskets')
  ),
  [ComponentEnum.FeaturedBrandsCarousel]: dynamic(
    () => import('./brands-carousel/brands-carousel')
  ),
  [ComponentEnum.FeaturedBrandsPanels]: dynamic(
    () => import('./brands-panels/brands-panels')
  ),
  [ComponentEnum.Footer]: dynamic(() => import('./footer/footer')),
  [ComponentEnum.ForgottenDetails]: dynamic(
    () => import('./forgotten-details/forgotten-details')
  ),
  [ComponentEnum.Form]: dynamic(() => import('./form/form')),
  [ComponentEnum.FreshRelevanceSmartBlock]: dynamic(
    () => import('./fresh-relevance-smart-block/fresh-relevance-smart-block')
  ),
  [ComponentEnum.HeadingWithCta]: dynamic(
    () => import('./heading-with-cta/heading-with-cta')
  ),
  [ComponentEnum.HeroBanner]: dynamic(
    () => import('./hero-text-banner/hero-text-banner')
  ),
  [ComponentEnum.HeroImageAndTextBanner]: dynamic(
    () => import('./hero-text-image-banner/hero-text-image-banner')
  ),
  [ComponentEnum.HoseBuilder]: dynamic(
    () => import('./hose-builder/hose-builder')
  ),
  [ComponentEnum.InlineCategoryBlock]: dynamic(
    () => import('./inline-category-block/inline-category-block')
  ),
  [ComponentEnum.InlineDownloadBlock]: dynamic(
    () => import('./inline-download-block/inline-download-block')
  ),
  [ComponentEnum.InlineFaqs]: dynamic(
    () => import('./inline-faqs/inline-faqs')
  ),
  [ComponentEnum.InlineImage]: dynamic(
    () => import('./inline-images/inline-images')
  ),
  [ComponentEnum.InlineVideo]: dynamic(
    () => import('./inline-video/inline-video')
  ),
  [ComponentEnum.InvoiceRequest]: dynamic(
    () => import('./invoice-request/invoice-request')
  ),
  [ComponentEnum.LatestCatalogueBlockContent]: dynamic(
    () => import('./latest-catalogue/latest-catalogue')
  ),
  [ComponentEnum.LatestNewsListing]: dynamic(
    () => import('./articles-carousel/articles-carousel')
  ),
  [ComponentEnum.LatestOrders]: dynamic(
    () => import('./latest-orders/latest-orders')
  ),
  [ComponentEnum.Login]: dynamic(() => import('./login/login')),
  [ComponentEnum.MapAndDirections]: dynamic(
    () => import('./map-and-directions/map-and-directions')
  ),
  [ComponentEnum.MyDetailsForm]: dynamic(
    () => import('./my-details-form/my-details-form')
  ),
  [ComponentEnum.NewsListing]: dynamic(
    () => import('./new-listing/news-listing')
  ),
  [ComponentEnum.OrderDetails]: dynamic(
    () => import('./order-details/order-details')
  ),
  [ComponentEnum.OrdersListing]: dynamic(
    () => import('./orders-listing/orders-listing')
  ),
  [ComponentEnum.ProductDetails]: dynamic(
    () => import('./product-details/product-details')
  ),
  [ComponentEnum.ProductListingWithFilters]: dynamic(
    () => import('./product-listing-with-filters/product-listing-with-filters')
  ),
  [ComponentEnum.PromoPanel]: dynamic(
    () => import('./promo-panel/promo-panel')
  ),
  [ComponentEnum.QuickAddTool]: dynamic(
    () => import('./quick-add-tool/quick-add-tool')
  ),
  [ComponentEnum.QuickAddMultiTool]: dynamic(
    () => import('./quick-add/quick-add')
  ),
  [ComponentEnum.Quote]: dynamic(() => import('./quote/quote')),
  [ComponentEnum.Redirect]: dynamic(() => import('./redirect/redirect')),
  [ComponentEnum.Registration]: dynamic(
    () => import('./registration/registration')
  ),
  [ComponentEnum.ResetPassword]: dynamic(
    () => import('./reset-password/reset-password')
  ),
  [ComponentEnum.Resources]: dynamic(() => import('./resources/resources')),
  [ComponentEnum.RetrieveQuote]: dynamic(
    () => import('./retrieve-quote/retrieve-quote')
  ),
  [ComponentEnum.RichText]: dynamic(() => import('./rich-text/rich-text')),
  [ComponentEnum.SaveAsFavouritesBasket]: dynamic(
    () => import('./save-as-favourites-basket/save-as-favourites-basket')
  ),
  [ComponentEnum.SearchResults]: dynamic(
    () => import('./search-results/search-results')
  ),
  [ComponentEnum.SignpostPanels]: dynamic(
    () => import('./signpost-panel-list/signpost-panel-list')
  ),
  [ComponentEnum.SocialMediaLinksWithText]: dynamic(
    () => import('./social-media-links-with-text/social-media-links-with-text')
  ),
  [ComponentEnum.SubAccountRegistration]: dynamic(
    () => import('./sub-account-registration/sub-account-registration')
  ),
  [ComponentEnum.SubtotalDeductions]: dynamic(
    () => import('./subtotal-deductions/subtotal-deductions')
  ),
  [ComponentEnum.TechnicalDatasheetsBlockContent]: dynamic(
    () => import('./technical-datasheets-block/technical-datasheets-block')
  ),
  [ComponentEnum.UpdateDetailsBlockContent]: dynamic(
    () => import('./update-details-block/update-details-block')
  ),
  [ComponentEnum.UspListBasket]: dynamic(
    () => import('../molecules/usp-list/usp-list-basket')
  ),
  [ComponentEnum.UserManagement]: dynamic(
    () => import('./user-management/user-management')
  ),
  [ComponentEnum.YouRecentlyViewed]: dynamic(
    () => import('./you-recently-viewed/you-recently-viewed')
  ),
};

export default Components;
