import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { ContentPageType } from '../../models/content-page-type';
import { UserStateContext } from '../../components/providers/user/user.provider';
import throwPagePermissionIssues from '../../helpers/throw-page-permission-issues';
import RedirectException from '../../services/exceptions/redirect-exception';

export default function usePermissionRedirect(page: ContentPageType): void {
  const router = useRouter();
  const { auth } = useContext(UserStateContext);

  useEffect(() => {
    try {
      throwPagePermissionIssues(auth, page);
    } catch (error) {
      if (error instanceof RedirectException) {
        router.replace(error.destination);
      }
    }
  }, [auth, router.isReady, page?.id]);
}
