import React, { FC } from 'react';
import { CheckoutBasketTableLabelsType } from '../../../models/checkout-basket-table-labels-type';
import { ProductBasketLineItemType } from '../../../models/product-basket-line-item-type';
import LoadingSpinner from '../../atoms/loading-spinner/loading-spinner';
import ProductImage from '../../atoms/product-image/product-image';
import ProductOverview from '../../atoms/product-overview/product-overview';
import TableData from '../../atoms/table-data/table-data';
import TableHeading from '../../atoms/table-heading/table-heading';
import useGetAvailabilities from '../../providers/basket/hooks/use-get-availabilities';
import Modal from '../modal/modal';

interface Props {
  checkoutLabels: CheckoutBasketTableLabelsType;
  lineItem: ProductBasketLineItemType;
  requiredQuantity: number;
}

const BasketLeadTimes: FC<Props> = ({
  checkoutLabels: {
    leadTimesPopupHeading,
    leadTimesPopupLeadTimeColumnLabel,
    leadTimesPopupQuantityColumnLabel,
  },
  lineItem,
  requiredQuantity,
}) => {
  const availabilities = useGetAvailabilities(lineItem, requiredQuantity);

  return (
    <Modal medium>
      {!availabilities && <LoadingSpinner />}
      {availabilities && (
        <div className="flex flex-col space-y-8 max-h-full overflow-y-auto">
          <h4 className="page-heading-four">{leadTimesPopupHeading}</h4>

          <div className="p-4 bg-grey-50 rounded-lg flex items-center space-x-4">
            <div className="w-16 h-16 p-2 bg-white rounded-lg">
              <ProductImage src={lineItem.product.properties.webImageSmall60} />
            </div>

            <ProductOverview lineItem={lineItem} />
          </div>

          <table>
            <thead>
              <tr className="border-b border-grey-100">
                <TableHeading label={leadTimesPopupQuantityColumnLabel} />
                <TableHeading label={leadTimesPopupLeadTimeColumnLabel} />
              </tr>
            </thead>

            <tbody>
              {availabilities.map(({ availability, quantity }) => (
                <tr className="border-b last:border-b-0 border-grey-100">
                  <TableData>{quantity}</TableData>

                  <TableData>{availability}</TableData>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  );
};

export default BasketLeadTimes;
