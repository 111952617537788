import { useContext } from 'react';
import { useRouter } from 'next/router';
import { BasketClearQuoteLabelsType } from '../../../../models/basket-clear-quote-labels-type';
import basketLoading from '../../basket/actions/basket-loading';
import reset from '../../basket/actions/reset';
import { BasketDispatchContext } from '../../basket/basket.provider';
import useClearBasket from '../../basket/hooks/use-clear-basket';
import deleteAuth from '../actions/delete-auth';
import { UserDispatchContext } from '../user.provider';

const useLogout = (): (() => Promise<void>) => {
  const userDispatch = useContext(UserDispatchContext);
  const basketDispatch = useContext(BasketDispatchContext);
  const clearBasket = useClearBasket({} as BasketClearQuoteLabelsType, {
    ignoreClearQuoteAlert: true,
  });
  const router = useRouter();

  return async () => {
    try {
      await clearBasket();
    } catch {
      basketDispatch(basketLoading(false));
    }

    basketDispatch(reset());
    userDispatch(deleteAuth());

    if (window.location.search.indexOf('debug') >= 0) {
      // eslint-disable-next-line no-debugger
      debugger;
    }
    router.push('/');
  };
};

export default useLogout;
