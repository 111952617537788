import Action from '../../../../types/action';
import BasketActions from '.';

const invalidateBasket = (): Action<BasketActions> => {
  return {
    type: BasketActions.InvalidateBasket,
  };
};

export default invalidateBasket;
