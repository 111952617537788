import React, { FC, ReactNode, useRef, useContext } from 'react';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';
import push from '../../providers/overlay/push.overlay.actions';
import pop from '../../providers/overlay/pop.overlay.actions';

interface Props {
  readonly align?: 'right' | 'top';
  readonly children: ReactNode;
  readonly description?: string;
  readonly label: ReactNode;
  readonly width?: 'sm' | 'md' | 'lg' | 'xl';
}

const ModalTooltip: FC<Props> = ({
  align,
  children,
  description,
  label,
  width,
}: Props) => {
  const overlayDispatch = useContext(OverlayDispatchContext);

  const containerRef = useRef<HTMLButtonElement>();

  const onClose = () => {
    overlayDispatch(pop());
  };

  const onOpen = () => {
    const rect = containerRef.current.getBoundingClientRect();

    let xPos = rect.left;

    if (align === 'top') {
      xPos += rect.width / 2;
    }

    if (align === 'right') {
      xPos -= 20;
    }

    let yPos = rect.top;

    if (align === 'top') {
      yPos -= 20;
    }

    if (align === 'right') {
      yPos += rect.height / 2;
    }

    overlayDispatch(
      push(
        <div
          className={`
            z-60 lg:absolute lg:transform p-4 lg:p-0 w-full
            ${width === 'sm' && 'max-w-sm'}
            ${width === 'md' && 'max-w-md'}
            ${width === 'lg' && 'max-w-lg'}
            ${width === 'xl' && 'max-w-xl'}
            ${align === 'top' && 'lg:-translate-y-full lg:-translate-x-1/2'}
            ${align === 'right' && 'lg:-translate-x-full lg:-translate-y-1/2'}
          `}
          style={{ left: `${xPos}px`, top: `${yPos}px` }}
        >
          <div className="relative bg-white p-8 text-sm rounded-lg text-left shadow-lg">
            {children}
            <button
              type="button"
              className="absolute right-0 top-0 p-3"
              onClick={() => onClose()}
            >
              <IcomoonIcon name="close" size="sm" />
            </button>
          </div>
          <div
            className={`
              hidden lg:block absolute w-4 h-4 transform rotate-45 bg-white
              ${align === 'top' && 'left-1/2 -bottom-2 -translate-x-2'}
              ${align === 'right' && 'top-1/2 -right-2 -translate-y-2'}
            `}
          />
        </div>
      )
    );
  };

  return (
    <>
      <span className="mr-2">{description}</span>

      <button ref={containerRef} onClick={() => onOpen()} type="button">
        {label}
      </button>
    </>
  );
};

ModalTooltip.defaultProps = {
  align: 'right',
  description: '',
  width: 'md',
};

export default ModalTooltip;
