import React, { Dispatch } from 'react';
import AlertModal from '../components/molecules/alert-modal/alert-modal';
import push from '../components/providers/overlay/push.overlay.actions';

export default function createAsyncAlertModal(
  overlayDispatchContext: Dispatch<unknown>,
  heading: string,
  message: string = null,
  submitMessage: string = null,
  cancelMessage: string = null,
  help: string = null
): Promise<void> {
  return new Promise((resolve, reject) => {
    overlayDispatchContext(
      push(
        <AlertModal
          cancelMessage={cancelMessage}
          heading={heading}
          headingHelp={help}
          message={message}
          onCancel={() => reject()}
          onSubmit={() => resolve()}
          submitMessage={submitMessage}
        />
      )
    );
  });
}
