import { ImageLoaderProps } from 'next/image';

const useNextImageLoader = ({
  src,
  quality,
  width,
}: ImageLoaderProps): string => {
  const params = [`w=${width}`, 'f=webp'];

  if (quality) {
    params.push(`q=${quality}`);
  }

  return `${src}?${params.join('&')}`;
};

export default useNextImageLoader;
