import { useContext } from 'react';
import { BasketDispatchContext } from '../basket.provider';
import useGetConfirmedBackOrderSkus from './use-get-confirmed-back-order-skus';
import replaceConfirmedBackOrderSkus from '../actions/replace-confirmed-back-order-skus';

const useAddConfirmedBackOrderSku: () => (sku: string) => void = () => {
  const skus = useGetConfirmedBackOrderSkus();
  const basketDispatch = useContext(BasketDispatchContext);

  return (sku: string) => {
    const updatedSkus = skus.includes(sku) ? [...skus] : [...skus, sku];

    basketDispatch(replaceConfirmedBackOrderSkus(updatedSkus));
  };
};

export default useAddConfirmedBackOrderSku;
