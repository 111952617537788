import dayjs from 'dayjs';
import React, { FC } from 'react';
import { NewsPagePropertiesType } from '../../../models/news-page-properties-type';
import InlineImage from '../../molecules/inline-image/inline-image';

interface Props extends NewsPagePropertiesType {
  readonly title: string;
}

const ArticlesTop: FC<Props> = ({
  category,
  desktopImage,
  mobileImage,
  publishDate,
  summary,
  tabletImage,
  title,
}: Props) => (
  <div className="py-component-spacing lg:py-component-spacing text-center">
    <div className="mx-auto w-full lg:w-10/12">
      <div className="flex items-center justify-center mb-3">
        <span className="font-bold mx-3 text-sm">{category}</span>

        <span className="font-bold mx-3 text-sm">
          {dayjs(publishDate).format('DD/MM/YYYY')}
        </span>
      </div>

      <h1 className="page-heading-three">{title}</h1>

      <div className="my-component-spacing-mobile lg:my-component-spacing">
        <InlineImage
          image={{
            desktopUrl: desktopImage.url,
            mobileUrl: mobileImage?.url,
            tabletUrl: tabletImage?.url,
          }}
          caption={desktopImage.altText}
        />
      </div>
    </div>

    <div className="mx-auto w-full md:w-10/12 lg:w-8/12">
      <h4 className="page-heading-four pb-component-spacing-mobile lg:pb-component-spacing-content">
        {summary}
      </h4>

      <hr className="border-grey-100 mt-component-spacing-mobile lg:mt-component-spacing" />
    </div>
  </div>
);

export default ArticlesTop;
