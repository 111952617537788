import { CategoryStructureType } from '../../../../models/category-structure-type';
import CultureCodeEnum from '../../../../models/culture-code-enum';
import client from '../../client-unauthenticated';

const categoryStructure = async (): Promise<CategoryStructureType> => {
  const { data } = await client.get<CategoryStructureType>(
    '/category/structure',
    {
      params: {
        domain: process.env.NEXT_PUBLIC_DOMAIN,
        cultureCode: CultureCodeEnum.EnGb,
      },
    }
  );

  return data;
};

export default categoryStructure;
