import ProductCard from "../product-card/product-card"

const HeaderLoadingList = ({count, isSearching}: any) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {Array(count || 6).fill("").map(loader => {
        return (
          <ProductCard
            basketItem={null}
            featured={null}
            horizontal
            labels={null}
            onClick={() => console.log('clicked')}
            onTechDataClick={() => null}
            isSearching={isSearching}
            specialPrice={null}
          />
        )
      })}
    </div>
  )
}

export default HeaderLoadingList;