import { useContext } from 'react';
import { BasketStateContext } from '../basket.provider';

const useGetConfirmedBackOrderSkus: () => string[] = () => {
  const basketState = useContext(BasketStateContext);

  return basketState?.confirmedBackOrderSkus || [];
};

export default useGetConfirmedBackOrderSkus;
