import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC, MouseEventHandler, useEffect, useState } from 'react';
import useIsBreakpoint from '../../../hooks/use-is-breakpoint/use-is-breakpoint';
import useNextImageLoader from '../../../hooks/use-next-image-loader/use-next-image-loader';
import { BasketAddProductErrorLabelsType } from '../../../models/basket-add-product-error-labels-type';
import { BasketClearQuoteLabelsType } from '../../../models/basket-clear-quote-labels-type';
import BreakpointEnum from '../../../models/breakpoint-enum';
import { ProductCardLabelsType } from '../../../models/product-card-labels-type';
import { ProductLineItemType } from '../../../models/product-line-item-type';
// import { SpecialPricingType } from '../../../models/special-pricing-type';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import ProductImage from '../../atoms/product-image/product-image';
import TextLink from '../../atoms/text-link/text-link';
import useUser from '../../providers/user/hooks/use-user.hook';
import AddToBasketButton from '../add-to-basket-button/add-to-basket-button';
// eslint-disable-next-line import/no-cycle
import AddToFavouritesButton from '../add-to-favourites-button/add-to-favourites-button';
import findMiddleImage from '../../../helpers/return-correct-property';
import ProductPricing from '../product-pricing/product-pricing';
import { SpecialPricingType } from '../../../models/special-pricing-type';

interface Props {
  readonly addProductErrorLabels?: BasketAddProductErrorLabelsType;
  readonly animate?: boolean;
  readonly basketItem: ProductLineItemType;
  readonly className?: string;
  readonly clearQuoteLabels?: BasketClearQuoteLabelsType;
  readonly featured?: boolean;
  readonly horizontal?: boolean;
  readonly isActive?: boolean;
  readonly isRecentlyViewed?: boolean;
  readonly labels: ProductCardLabelsType;
  readonly onClick?: MouseEventHandler;
  readonly onTechDataClick?: MouseEventHandler;
  readonly onAddToFavouriteClick?: MouseEventHandler;
  readonly small?: boolean;
  readonly specialPrice?: SpecialPricingType | null;
  readonly stockQuantity?: number;
  readonly hideStockAmount?: boolean;
  readonly isSearching?: boolean;
}

const ProductCard: FC<Props> = ({
  addProductErrorLabels,
  animate,
  basketItem,
  className,
  clearQuoteLabels,
  featured,
  horizontal,
  isActive,
  isRecentlyViewed,
  labels,
  onClick,
  onTechDataClick,
  onAddToFavouriteClick,
  small,
  specialPrice,
  stockQuantity,
  hideStockAmount,
  isSearching,
}) => {
  const [showAddToBasket, setShowAddToBasket] = useState<boolean>(false);
  const breakpoint = useIsBreakpoint();
  const user = useUser();
  const transition = {
    damping: 18,
    delay: 0.1,
    duration: 0.5,
    type: 'spring',
  };

  const getClassNames = (): string => {
    if (!isSearching && featured) {
      return 'bg-light-blue';
    }

    if (small || horizontal) {
      return 'bg-grey-50';
    }

    return 'bg-grey-20';
  };

  const getCardClassNames = (): string => {
    if (!isSearching && horizontal) {
      return featured ? 'mt-0' : 'pt-1px mt-5';
    }

    if (isRecentlyViewed) {
      return 'px-16 lg:px-24 xl:px-20 py-7';
    }

    return 'px-16 xl:px-10 py-7';
  };

  useEffect(() => {
    setShowAddToBasket(true);
  }, [breakpoint]);

  const middleImgSource = findMiddleImage(
    basketItem?.product?.properties || {}
  );
  return (
      <div
        className={`
        ${className}
        ${getClassNames()}
        ${horizontal ? 'flex-row space-x-4' : 'flex-col'}
        flex h-full justify-between px-4 lg:px-6 py-4 rounded-lg
      `}
      >
        <div className={horizontal ? 'w-5/12' : ''}>
          {!isSearching && featured && (
            <div className="flex justify-between items-center">
              <span className="uppercase text-french-blue text-sm font-bold">
                Featured
              </span>

              {!isSearching &&
                !horizontal &&
                basketItem?.product?.properties?.brandLogoImage && (
                  <Image
                    alt={basketItem?.product?.properties?.brand || ''}
                    height={48}
                    loader={useNextImageLoader}
                    objectFit="contain"
                    src={basketItem?.product?.properties?.brandLogoImage || ''}
                    width={48}
                  />
                )}
            </div>
          )}

          {!featured && !horizontal && (
            <div className="text-grey-600 text-sm text-center font-medium">
              {basketItem?.product?.properties?.brand}
            </div>
          )}

          {middleImgSource?.source && (
            <motion.div
              animate={
                !animate || (animate && isActive) ? 'isActive' : 'notActive'
              }
              className={`${getCardClassNames()} relative w-full`}
              transition={transition}
              variants={{
                isActive: { opacity: 1 },
                notActive: { opacity: 0 },
              }}
            >
              {basketItem?.product?.url && (
                <Link href={basketItem?.product?.url || ''} passHref>
                  <a href="replace" onClick={onClick}>
                    {basketItem?.product?.name && (
                      <ProductImage
                        alt={basketItem?.product?.name || ''}
                        src={middleImgSource?.source || ''}
                      />
                    )}
                  </a>
                </Link>
              )}

              {!basketItem?.product?.url && (
                <ProductImage
                  alt={basketItem?.product?.name || ''}
                  src={middleImgSource?.source || ''}
                />
              )}
            </motion.div>
          )}
        </div>

        <motion.div
          animate={!animate || (animate && isActive) ? 'isActive' : 'notActive'}
          transition={transition}
          variants={{
            isActive: { opacity: 1, translateY: 0 },
            notActive: { opacity: 0, translateY: 10 },
          }}
          className="flex flex-col w-full"
        >
          {basketItem?.product?.url && (
            <Link href={basketItem?.product?.url || ''} passHref>
              <a
                className={
                  `${
                    horizontal ? 'border-b border-grey-200 pb-2' : 'py-2.5'
                  } ` + 'hover:text-french-blue text-md'
                }
                href="replace"
                onClick={onClick}
              >
                {basketItem?.product?.name}
              </a>
            </Link>
          )}

          {!basketItem?.product?.url && (
            <span className="py-2.5 hover:text-french-blue text-md">
              {basketItem?.product?.name}
            </span>
          )}

          <div>
            <div className="leading-none flex space-x-1">
              <span className="flex-shrink-0 text-xs text-grey-600">
                {labels?.productCodeLabel}
              </span>

              <span className="text-xs truncate">
                {basketItem?.product?.variantSku}
              </span>
            </div>

            {basketItem?.product?.properties?.manufacturersCode &&
              basketItem?.product?.properties?.manufacturersCode !==
                basketItem?.product?.variantSku && (
                <div className="leading-none flex space-x-1">
                  <span className="flex-shrink-0 text-xs text-grey-600">
                    {labels?.manufacturersReferenceLabel}
                  </span>

                  <span className="text-xs truncate">
                    {basketItem?.product?.properties?.manufacturersCode}
                  </span>
                </div>
              )}

            {!small && !!basketItem?.product?.properties?.techData?.length && (
              <div className="leading-none flex space-x-1">
                <span className="flex-shrink-0 text-xs text-grey-600">
                  {labels?.technicalSheetLabel}
                </span>

                <TextLink
                  name={
                    <div className="flex space-x-1 items-center">
                      <span className="text-xs">
                        {basketItem?.product?.properties?.techDataRef}
                      </span>

                      <IcomoonIcon
                        className="-mt-2px"
                        name="download"
                        color="french-blue"
                        size="xs"
                      />
                    </div>
                  }
                  url={basketItem?.product?.properties?.techData || ''}
                  onClick={onTechDataClick}
                  target="_blank"
                />
              </div>
            )}
          </div>

          {!small && (
            <ProductPricing
              basketItem={basketItem || {}}
              horizontal={horizontal}
              labels={labels}
              specialPrice={specialPrice}
            />
          )}

          {!small &&
            labels?.addToBasketLabel &&
            labels?.addToFavouritesLabel &&
            showAddToBasket && (
              <div>
                <AddToBasketButton
                  addToBasketLabel={labels?.addToBasketLabel}
                  addProductErrorLabels={{
                    addProductErrorHeading:
                      addProductErrorLabels?.addProductErrorHeading,
                    addProductErrorDescription:
                      addProductErrorLabels?.addProductErrorDescription,
                    addProductErrorBody:
                      addProductErrorLabels?.addProductErrorBody,
                    insufficientClearanceStockError:
                      addProductErrorLabels?.insufficientClearanceStockError,
                  }}
                  clearQuoteLabels={{
                    clearQuoteAccept: clearQuoteLabels?.clearQuoteAccept,
                    clearQuoteCancel: clearQuoteLabels?.clearQuoteCancel,
                    clearQuoteDescription:
                      clearQuoteLabels?.clearQuoteDescription,
                    clearQuoteHeading: clearQuoteLabels?.clearQuoteHeading,
                    clearQuoteHelp: clearQuoteLabels?.clearQuoteHelp,
                  }}
                  paddingX={
                    breakpoint === BreakpointEnum.sm ||
                    breakpoint === BreakpointEnum.md ||
                    breakpoint === BreakpointEnum.lg ||
                    isRecentlyViewed
                      ? 'full'
                      : 'half'
                  }
                  product={basketItem?.product || null}
                  stockQuantity={stockQuantity}
                  alwaysShowStockAmount={false}
                  hideStockAmount={hideStockAmount}
                />

                {/* {user && (
                <div className="mt-2 flex flex-col items-center">
                  <AddToFavouritesButton
                    basketItem={basketItem}
                    labels={labels}
                    onAddClick={onAddToFavouriteClick}
                  />
                </div>
              )} */}
              </div>
            )}
        </motion.div>
      </div>
  );
};

ProductCard.defaultProps = {
  addProductErrorLabels: null,
  animate: false,
  clearQuoteLabels: null,
  className: '',
  featured: false,
  horizontal: false,
  isActive: false,
  isRecentlyViewed: false,
  onClick: () => ({}),
  onTechDataClick: () => ({}),
  onAddToFavouriteClick: () => ({}),
  small: false,
  // specialPrice: null,
  stockQuantity: null,
  hideStockAmount: false,
  isSearching: false,
};

export default ProductCard;
