enum PlacementEnum {
  BasketDetails = 'Basket Details',
  Dashboard = 'Dashboard',
  FavouriteBaskets = 'Favourite Baskets',
  OrderDetails = 'Order Details',
  OrderListing = 'Order Listing',
  ProductDetails = 'Product Details',
  ProductListing = 'Product Listing',
}

export default PlacementEnum;
