import React, { useContext } from 'react';
import calculateLineItemQuantity from '../../../../helpers/calculate-line-item-quantity';
import interpolateContent from '../../../../helpers/interpolate-content';
import { BasketAddProductErrorLabelsType } from '../../../../models/basket-add-product-error-labels-type';
import { BasketClearQuoteLabelsType } from '../../../../models/basket-clear-quote-labels-type';
import BasketLineItemIssueEnum from '../../../../models/basket-line-item-issue-enum';
import { BasketType } from '../../../../models/basket-type';
import { ProductBasketLineItemType } from '../../../../models/product-basket-line-item-type';
import guestAddLineItem from '../../../../services/content-api/endpoints/basket/guest/add-line-item';
import memberAddLineItem from '../../../../services/content-api/endpoints/basket/member/add-line-item';
import categoryStructure from '../../../../services/content-api/endpoints/category/structure';
import AddToBasketErrorsModal from '../../../molecules/add-to-basket-errors-modal/add-to-basket-errors-modal';
import open from '../../overlay/open.overlay.actions';
import { OverlayDispatchContext } from '../../overlay/overlay.provider';
import useGetSessionId from '../../user/hooks/use-get-session-id.hook';
import { UserStateContext } from '../../user/user.provider';
import basketLoading from '../actions/basket-loading';
import updateBasket from '../actions/update-basket';
import { BasketDispatchContext, BasketStateContext } from '../basket.provider';
import useAddConfirmedBackOrderSku from './use-add-confirmed-back-order-sku';
import useClearBasket from './use-clear-basket';
import setLastAddedVariantSkus from '../actions/set-last-added-variant-sku';

const useAddToBasket: (
  addProductErrorLabels: BasketAddProductErrorLabelsType,
  clearQuoteLabels: BasketClearQuoteLabelsType
) => (variantSku: string, quantity: number) => Promise<void> = (
  addProductErrorLabels,
  clearQuoteLabels
) => {
  const { auth } = useContext(UserStateContext);
  const basketDispatch = useContext(BasketDispatchContext);
  const basketState = useContext(BasketStateContext);
  const overlayDispatch = useContext(OverlayDispatchContext);
  const clearBasket = useClearBasket(clearQuoteLabels);
  const getSessionId = useGetSessionId();
  const addConfirmedBackOrderSku = useAddConfirmedBackOrderSku();

  return async (variantSku: string, quantity: number) => {
    basketDispatch(basketLoading(true));

    const { catalogId } = await categoryStructure();

    try {
      if (basketState.quote) {
        await clearBasket();
      }
    } catch {
      basketDispatch(basketLoading(false));

      return;
    }

    let basket: BasketType;

    try {
      basket = auth.accessToken
        ? await memberAddLineItem(
            catalogId,
            await getSessionId(),
            basketState.id,
            variantSku,
            quantity
          )
        : await guestAddLineItem(
            catalogId,
            await getSessionId(),
            basketState.id,
            variantSku,
            quantity
          );
    } catch (error) {
      basketDispatch(basketLoading(false));

      throw error;
    }

    basketDispatch(updateBasket(basket));
    basketDispatch(basketLoading(false));

    if (
      basket.lineItemIssues?.length &&
      basket.lineItemIssues
        ?.map((issue) => issue.variantSku.toUpperCase())
        .includes(variantSku.toUpperCase())
    ) {
      const foundItemIssue = basket.lineItemIssues.find(
        (issue) => issue.variantSku.toUpperCase() === variantSku.toUpperCase()
      );

      const issueDescription =
        foundItemIssue?.errorMessage ===
        BasketLineItemIssueEnum.InsufficientClearanceStock
          ? addProductErrorLabels.insufficientClearanceStockError ||
            'Insufficient Clearance Stock'
          : interpolateContent(
              addProductErrorLabels.addProductErrorDescription,
              foundItemIssue?.errorMessage || 'Error'
            );

      overlayDispatch(
        open(
          <AddToBasketErrorsModal
            heading={addProductErrorLabels.addProductErrorHeading}
            description={issueDescription}
            body={addProductErrorLabels.addProductErrorBody}
            skus={[variantSku]}
          />
        )
      );
    }

    const foundBasketItem = basket.lineItems.find(
      (basketItem: ProductBasketLineItemType) =>
        basketItem.variantSku.toUpperCase() === variantSku.toUpperCase()
    );

    if (
      foundBasketItem &&
      calculateLineItemQuantity(foundBasketItem)?.backOrder
    ) {
      addConfirmedBackOrderSku(variantSku);
    }

    if (foundBasketItem) {
      basketDispatch(setLastAddedVariantSkus([variantSku]));
      setTimeout(() => {
        basketDispatch(setLastAddedVariantSkus([]));
      }, 5000);
    }

    basketDispatch(basketLoading(false));
  };
};

export default useAddToBasket;
