import Link from 'next/link';
import React, { FC, MouseEvent, useContext } from 'react';
import { CategoryType } from '../../../models/category-type';
import { ContentPageHeaderNavItemType } from '../../../models/content-page-header-nav-item-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';

interface Props {
  readonly links: ContentPageHeaderNavItemType[] | CategoryType[];
  readonly onClick: (
    event: MouseEvent,
    link: ContentPageHeaderNavItemType | CategoryType
  ) => void;
  readonly onFocus: (link: ContentPageHeaderNavItemType | CategoryType) => void;
  readonly onMouseOver: (
    link: ContentPageHeaderNavItemType | CategoryType
  ) => void;
  readonly selectedLink?: ContentPageHeaderNavItemType | CategoryType;
}

const HeaderMegaMenuLinks: FC<Props> = ({
  links,
  onClick,
  onFocus,
  onMouseOver,
  selectedLink,
}: Props) => {
  const hasChildren = (
    link: ContentPageHeaderNavItemType | CategoryType
  ): boolean =>
    (link as ContentPageHeaderNavItemType)?.childNavigationItems?.length > 0 ||
    (link as CategoryType)?.childCategoryItems?.length > 0;
  const isViewAllLink = (
    link: ContentPageHeaderNavItemType | CategoryType
  ): boolean => (link as CategoryType)?.name === 'View all';
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  return (
    <ul className="flex flex-col justify-center -ml-3 -mr-8">
      {links.map((link: ContentPageHeaderNavItemType | CategoryType) => (
        <li
          key={
            (link as ContentPageHeaderNavItemType)?.link?.url ||
            (link as CategoryType)?.url
          }
        >
          <Link
            href={
              (link as ContentPageHeaderNavItemType)?.link?.url ||
              (link as CategoryType)?.url
            }
            passHref
          >
            <a
              className={
                `${link === selectedLink ? 'bg-light-blue ' : ''}` +
                `${isViewAllLink(link) ? 'text-french-blue underline ' : ''}` +
                'flex items-center justify-between focus:outline-none pl-3 pr-8 py-2 rounded-l-lg w-full'
              }
              href="replace"
              onClick={(event: MouseEvent) => {
                onClick(event, link);

                pushToDataLayer(GtmEventNameEnum.NavSubMenu);
              }}
              onFocus={() => onFocus(link)}
              onMouseOver={() => onMouseOver(link)}
              target={
                (link as ContentPageHeaderNavItemType)?.link?.target || '_self'
              }
            >
              <span className={link === selectedLink ? 'font-bold' : ''}>
                {(link as ContentPageHeaderNavItemType)?.link?.name ||
                  (link as CategoryType)?.name}
              </span>

              {hasChildren(link) && (
                <IcomoonIcon
                  className="ml-2"
                  color={link === selectedLink ? 'french-blue' : 'grey-200'}
                  name="chevron-right"
                  size="sm"
                />
              )}
            </a>
          </Link>
        </li>
      ))}
    </ul>
  );
};

HeaderMegaMenuLinks.defaultProps = {
  selectedLink: null,
};

export default HeaderMegaMenuLinks;
