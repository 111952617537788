import { useContext, useEffect, useState } from 'react';
import { UserStateContext } from '../../user/user.provider';
import { ProductBasketLineItemType } from '../../../../models/product-basket-line-item-type';
import getAvailability from '../../../../services/content-api/endpoints/basket/stock/availability';
import { ProductBasketLineItemAvailabilityType } from '../../../../models/product-basket-line-item-availability-type';
import { BasketStateContext } from '../basket.provider';

const useGetAvailabilities = (
  lineItem: ProductBasketLineItemType,
  quantity: number
): ProductBasketLineItemAvailabilityType[] => {
  const [availability, setAvailability] =
    useState<ProductBasketLineItemAvailabilityType[]>();

  const { auth } = useContext(UserStateContext);
  const { basket } = useContext(BasketStateContext);

  useEffect(() => {
    getAvailability(
      auth.sessionId,
      basket?.id,
      lineItem.productNumber,
      quantity
    ).then((response) => setAvailability(response));
  }, []);

  return availability;
};

export default useGetAvailabilities;
