import { AuthState } from '../components/providers/user/user.state';
import { ContentPageType } from '../models/content-page-type';
import RedirectException from '../services/exceptions/redirect-exception';

const throwPagePermissionIssues = (
  auth: AuthState,
  page: ContentPageType | null
): void => {
  if (auth.accessToken && page?.properties?.hideFromAuthenticatedUsers) {
    throw new RedirectException('/');
  }

  if (!auth.accessToken && page?.properties?.hideFromUnauthenticatedUsers) {
    throw new RedirectException('/login');
  }

  const redirectComponent = page?.regions?.main?.components?.find(
    (component: { name: string }) => component.name === 'redirectComponent'
  );

  if (redirectComponent) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    throw new RedirectException((redirectComponent.data as any).url);
  }
};

export default throwPagePermissionIssues;
