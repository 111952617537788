import Link from 'next/link';
import React, { FC } from 'react';
import truncate from '../../../helpers/truncate';
import { BreadcrumbType } from '../../../models/breadcrumb-type';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import TextTooltip from '../../atoms/text-tooltip/text-tooltip';

const Breadcrumb: FC<BreadcrumbType> = ({
  links,
  secondaryComponent,
}: BreadcrumbType) => (
  <>
    {links?.length > 1 && (
      <>
        <nav className="w-full flex justify-center lg:justify-between items-center z-10">
          <ol className="hidden lg:flex list-reset py-4 rounded bg-grey-light text-grey">
            {links
              .slice()
              .reverse()
              .map((link, index, { length }) => {
                const last = length === index + 1;
                const colourClasses = last
                  ? 'text-french-blue'
                  : 'text-grey-600 hover:text-french-blue';

                return (
                  <li key={link.url} className="flex flex-nowrap items-center">
                    <TextTooltip
                      enabled={link.name.length > 25}
                      label={link.name}
                    >
                      <Link href={link.url} passHref>
                        <a
                          href="replace"
                          target={link.target}
                          className={`${colourClasses} text-center`}
                        >
                          {truncate(link.name, 25)}
                        </a>
                      </Link>
                    </TextTooltip>

                    {index + 1 !== length && (
                      <IcomoonIcon
                        className="-mb-2px px-6"
                        color="grey-600"
                        name="chevron-right"
                        size="md"
                      />
                    )}
                  </li>
                );
              })}
          </ol>
          {secondaryComponent}
        </nav>
      </>
    )}
  </>
);

export default Breadcrumb;
