import React, { createElement, FC, useContext } from 'react';
import { ContentPageComponentDataType } from '../../../models/content-page-component-data-type';
import { ContentPageType } from '../../../models/content-page-type';
import AnimationFadeIn from '../../animations/animation-fade-in/animation-fade-in';
import ComponentRenderError from '../../atoms/component-render-error/component-render-error';
import Layout from '../../layout/layout';
import Meta from '../../molecules/meta/meta';
import Breadcrumb from '../../organisms/breadcrumb/breadcrumb';
import Components from '../../organisms/components';
import Footer from '../../organisms/footer/footer';
import Header from '../../organisms/header/header';
import { OverlayStateContext } from '../../providers/overlay/overlay.provider';
import PageSpecificComponents from '../page-specifc-components/page-specifc-components';
import styles from '../page.module.scss';
import usePermissionRedirect from '../../../hooks/use-permission-redirect/use-permission-redirect';

const ContentPage: FC<ContentPageType> = (page: ContentPageType) => {
  usePermissionRedirect(page);
  const overlayState = useContext(OverlayStateContext);
  const { regions } = page;

  return (
    <>
      <Meta page={page} />

      <Header
        {...regions?.header}
        className={
          overlayState.components.length && !overlayState.keepNav
            ? styles.blur
            : ''
        }
        isAuthenticatedPage={page.properties?.hideFromUnauthenticatedUsers}
      />

      <div className={overlayState.components.length ? styles.blur : ''}>
        <main>
          <Layout className="pb-component-spacing-mobile lg:pb-component-spacing">
            {!page.properties.hideBreadcrumb && (
              <Breadcrumb links={regions.header.breadcrumb} />
            )}

            <PageSpecificComponents {...page} />

            {regions.main.components.map(
              (component: {
                name: string;
                data: ContentPageComponentDataType;
              }) => {
                if (!Components[component.name]) {
                  return (
                    <ComponentRenderError
                      key={component.data?.id}
                      name={component.name}
                    />
                  );
                }

                return (
                  <AnimationFadeIn key={component.data.id}>
                    {createElement(
                      Components[component.name],
                      component.data.properties
                        ? {
                            ...component.data.properties,
                          }
                        : { ...component.data }
                    )}
                  </AnimationFadeIn>
                );
              }
            )}
          </Layout>
        </main>

        <Footer
          {...regions.footer}
          isAuthenticatedPage={page.properties.hideFromUnauthenticatedUsers}
        />
      </div>
    </>
  );
};

export default ContentPage;
