import React, {
  FC,
  MouseEvent,
  ReactEventHandler,
  useContext,
  useState,
} from 'react';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import ButtonSecondary from '../../atoms/button-secondary/button-secondary';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import ModalTooltip from '../../atoms/modal-tooltip/modal-tooltip';
import close from '../../providers/overlay/close.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import Modal from '../modal/modal';
import RichText from '../rich-text/rich-text';

interface Props {
  readonly cancelMessage?: string;
  readonly heading: string;
  readonly headingHelp?: string;
  readonly message?: string;
  readonly onCancel?: ReactEventHandler;
  readonly onSubmit?: ReactEventHandler;
  readonly size?: 'alert' | 'medium';
  readonly submitMessage?: string;
}

const AlertModal: FC<Props> = ({
  cancelMessage,
  heading,
  headingHelp,
  message,
  onCancel,
  onSubmit,
  size,
  submitMessage,
}: Props) => {
  const overlayDispatch = useContext(OverlayDispatchContext);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  return (
    <Modal alert={size === 'alert'} medium={size === 'medium'}>
      <div className="flex flex-col items-center text-center">
        <div className="flex items-center space-x-2">
          <h4 className="page-heading-four">{heading}</h4>
          {headingHelp && (
            <ModalTooltip
              align="top"
              label={
                <div className="flex">
                  <IcomoonIcon
                    name="question-circle"
                    color="french-blue"
                    size="lg"
                  />
                </div>
              }
            >
              <RichText>{headingHelp}</RichText>
            </ModalTooltip>
          )}
        </div>

        {message && <RichText className="mt-1">{message}</RichText>}

        {!(!cancelMessage && !submitMessage) && (
          <div className="flex flex-col-reverse md:flex-row justify-center mt-8 md:space-x-4 w-full">
            {cancelMessage && (
              <ButtonSecondary
                name={cancelMessage}
                disabled={isSubmitted}
                onClick={async (event) => {
                  setIsSubmitted(true);
                  await onCancel(event);
                  overlayDispatch(close());
                }}
                paddingX={size === 'alert' ? 'half' : 'full'}
              />
            )}

            {submitMessage && (
              <ButtonPrimary
                className="mb-6 md:mb-0"
                name={submitMessage}
                disabled={isSubmitted}
                onClick={async (event: MouseEvent<HTMLButtonElement>) => {
                  setIsSubmitted(true);
                  await onSubmit(event);
                  overlayDispatch(close());
                }}
                paddingX={size === 'alert' ? 'half' : 'full'}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

AlertModal.defaultProps = {
  cancelMessage: null,
  headingHelp: null,
  message: null,
  onCancel: () => ({}),
  onSubmit: () => ({}),
  size: 'alert',
  submitMessage: null,
};

export default AlertModal;
