import Primitive from '../types/primitive';

export default function interpolateContent(
  content: string,
  ...inserts: Primitive[]
): string {
  return inserts.reduce<string>(
    (interpolatedContent, insert, index) =>
      interpolatedContent.split(`{${index}}`).join(String(insert)),
    content || ''
  );
}
