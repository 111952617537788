import React, { FC } from 'react';
import Modal from '../modal/modal';
import RichText from '../rich-text/rich-text';

interface Props {
  readonly heading: string;
  readonly description: string;
  readonly body: string;
  readonly skus: string[];
}

const AddToBasketErrorsModal: FC<Props> = ({
  heading,
  description,
  body,
  skus,
}: Props) => {
  return (
    <Modal medium>
      <div className="space-y-3">
        <h4 className="page-heading-four">{heading}</h4>
        <RichText className="text-grey-600">{description}</RichText>
        <ul className="list-disc pl-5 max-h-64">
          {skus.map((sku) => (
            <li className="font-bold" key={sku}>
              {sku}
            </li>
          ))}
        </ul>
        <RichText className="text-grey-600">{body}</RichText>
      </div>
    </Modal>
  );
};

export default AddToBasketErrorsModal;
