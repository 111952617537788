import Action from '../../../../types/action';
import BasketActions from '.';

const replaceConfirmedBackOrderSkus = (
  skus: string[]
): Action<BasketActions> => {
  return {
    type: BasketActions.ReplaceConfirmedBackOrderSkus,
    payload: skus,
  };
};

export default replaceConfirmedBackOrderSkus;
