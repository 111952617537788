import React, { FC } from 'react';
import useNextImageLoader from '../../../hooks/use-next-image-loader/use-next-image-loader';
import { ImageType } from '../../../models/image-type';
import Image from '../../atoms/image/image';

interface Props {
  image: ImageType;
  caption?: string;
}

const InlineImage: FC<Props> = ({ image, caption }: Props) => {
  return (
    <figure className="w-full">
      <Image
        alt={caption}
        loader={useNextImageLoader}
        model={image}
        className="rounded-xl"
      />
      {caption && (
        <figcaption className="mt-2 text-sm text-grey-600">
          {caption}
        </figcaption>
      )}
    </figure>
  );
};

InlineImage.defaultProps = {
  caption: null,
};

export default InlineImage;
