import { useContext } from 'react';
import { UserStateContext } from '../user.provider';

const useIsLoggedIn = (): boolean => {
  const { auth } = useContext(UserStateContext);

  return !!auth?.accessToken;
};

export default useIsLoggedIn;
