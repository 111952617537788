import { useEffect } from 'react';

const useAccountMenuOutsideHover = (
  handler: (event: Event) => void
): ReturnType<typeof useEffect> => {
  let debounce: NodeJS.Timeout;

  const onEnter = (): void => {
    if (debounce) {
      clearTimeout(debounce);
    }
  };

  const onLeave = (event: Event): void => {
    debounce = setTimeout(() => handler(event), 100);
  };

  useEffect(() => {
    const navList = document.querySelector('#account-header-navigation-list');
    const megaMenu = document.querySelector('#account-header-mega-menu');

    [navList, megaMenu].forEach((element: Element) => {
      element.addEventListener('mouseenter', onEnter);
      element.addEventListener('mouseleave', onLeave);
    });

    return () => {
      [navList, megaMenu].forEach((element: Element) => {
        element.addEventListener('mouseenter', onEnter);
        element.removeEventListener('mouseleave', onLeave);
      });
    };
  }, [handler]);
};

export default useAccountMenuOutsideHover;
