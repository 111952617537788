import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { FC, useContext } from 'react';
import useAccountMenuOutsideHover from '../../../hooks/use-account-menu-outside-hover/use-account-menu-outside-hover';
import useIsBreakpoint from '../../../hooks/use-is-breakpoint/use-is-breakpoint';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import BreakpointEnum from '../../../models/breakpoint-enum';
import { ContentPageHeaderType } from '../../../models/content-page-header-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import TextLink from '../../atoms/text-link/text-link';
import close from '../../providers/overlay/close.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import useIsLoggedIn from '../../providers/user/hooks/use-is-logged-in';
import useLogout from '../../providers/user/hooks/use-logout.hook';
import HeaderAccountDropdownLink from '../header-account-dropdown-link/header-account-dropdown-link';

interface Props extends ContentPageHeaderType {
  readonly isAuthenticatedPage: boolean;
  readonly menuRect: DOMRect;
}

const HeaderAccountDropdown: FC<Props> = ({
  accountCreationPageLink,
  accountDashboardPageLink,
  accountDetailsPageLink,
  accountFavouritesBasketPageLink,
  accountLiteraturePageLink,
  accountLoginPageLink,
  accountOrdersPageLink,
  isAuthenticatedPage,
  logoutLabel,
  menuRect,
}: Props) => {
  const overlayDispatch = useContext(OverlayDispatchContext);
  const isLoggedIn = useIsLoggedIn();
  const breakpoint = useIsBreakpoint();
  const logout = useLogout();
  const router = useRouter();
  useAccountMenuOutsideHover(() => overlayDispatch(close()));
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  const springTransition = {
    damping: 24,
    duration: 0,
    mass: 0.8,
    stiffness: 200,
    type: 'spring',
  };

  const arrowLeft = `calc(${menuRect.left + menuRect.width / 2}px - 0.625rem)`;
  const contentLeft =
    `calc(${menuRect.left + menuRect.width / 2}px - ` +
    `${breakpoint === BreakpointEnum.md ? 14 : 9}rem)`;

  return (
    <nav>
      <motion.div
        animate={{ opacity: 1 }}
        className="absolute bg-white duration-400 ease-out h-5 rotate-45 shadow transform transition-all -top-18 lg:-top-12 w-5"
        style={{
          left: arrowLeft,
        }}
        initial={{ opacity: 0 }}
        transition={springTransition}
      />

      <motion.div
        animate={{ opacity: 1, y: 0 }}
        className="absolute bg-white flex mx-4 md:mx-0 origin-center px-8 py-4 rounded shadow-lg -top-16 lg:-top-10"
        id="account-header-mega-menu"
        initial={{ opacity: 0, y: -20 }}
        style={{
          left: breakpoint === BreakpointEnum.sm ? 0 : contentLeft,
          width:
            breakpoint === BreakpointEnum.sm ? 'calc(100% - 2rem)' : '18rem',
        }}
        transition={springTransition}
      >
        <motion.div
          animate={{ opacity: 1 }}
          className="flex flex-col justify-between min-h-full w-full"
          initial={{ opacity: 0 }}
          transition={{
            delay: 0.2,
            duration: 0.3,
          }}
        >
          <div className="flex flex-col items-center text-center w-full">
            {!isLoggedIn && (
              <ButtonPrimary
                {...accountLoginPageLink}
                className="my-2"
                onClick={() => {
                  overlayDispatch(close());

                  pushToDataLayer(GtmEventNameEnum.NavLogin);
                }}
              />
            )}

            {isLoggedIn && (
              <ButtonPrimary
                {...accountDashboardPageLink}
                className="my-2"
                onClick={() => {
                  overlayDispatch(close());

                  pushToDataLayer(GtmEventNameEnum.AccountNav);
                }}
              />
            )}

            {isLoggedIn && (
              <ul className="w-10/12">
                <HeaderAccountDropdownLink {...accountOrdersPageLink} />
                <HeaderAccountDropdownLink {...accountDetailsPageLink} />
                <HeaderAccountDropdownLink
                  {...accountFavouritesBasketPageLink}
                />
                <HeaderAccountDropdownLink {...accountLiteraturePageLink} />
              </ul>
            )}

            {!isLoggedIn && (
              <TextLink
                className="my-2"
                name={accountCreationPageLink.name}
                onClick={() => overlayDispatch(close())}
                url={accountCreationPageLink.url}
              />
            )}

            {isLoggedIn && (
              <TextLink
                className="my-2"
                name={logoutLabel}
                onClick={() => {
                  overlayDispatch(close());
                  logout();

                  if (isAuthenticatedPage) {
                    router.push('/');
                  }
                }}
              />
            )}
          </div>
        </motion.div>
      </motion.div>
    </nav>
  );
};

export default HeaderAccountDropdown;
