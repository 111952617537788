import React, { FC, ReactNode } from 'react';

interface Props {
  readonly children: ReactNode;
  readonly color?: string;
}

const DiagonalStrikethrough: FC<Props> = ({ children, color }) => (
  <div className="relative">
    {children}
    <div className="absolute inset-0">
      <svg
        className={`stroke-current text-${color}`}
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          strokeWidth="2"
          strokeLinecap="round"
          y2="95%"
          x2="5%"
          y1="5%"
          x1="95%"
        />
      </svg>
    </div>
  </div>
);

DiagonalStrikethrough.defaultProps = {
  color: 'black',
};

export default DiagonalStrikethrough;
