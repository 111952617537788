import React, { FC } from 'react';
import ContentPageDocumentTypeEnum from '../../../models/content-page-document-type-enum';
import { ContentPageType } from '../../../models/content-page-type';
import ArticlesTop from '../../organisms/articles-top/articles-top';

const PageSpecificComponents: FC<ContentPageType> = (page: ContentPageType) => (
  <>
    {page.documentType === ContentPageDocumentTypeEnum.NewsArticlePage && (
      <ArticlesTop {...page.properties} title={page.name} />
    )}
  </>
);

export default PageSpecificComponents;
