import Link from 'next/link';
import React, { FC, useContext } from 'react';
import Ripples from 'react-ripples';
import getTailwindConfig from '../../../helpers/get-tailwind-config';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { CategoryType } from '../../../models/category-type';
import { ContentPageHeaderNavItemType } from '../../../models/content-page-header-nav-item-type';
import { ContentPageHeaderProductNavType } from '../../../models/content-page-header-product-nav-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import close from '../../providers/overlay/close.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';

interface Props {
  readonly isContactLink?: boolean;
  // eslint-disable-next-line prettier/prettier
  readonly link:
    | ContentPageHeaderProductNavType
    | ContentPageHeaderNavItemType
    | CategoryType
    | any
  readonly onClick: (
    link:
      | ContentPageHeaderProductNavType
      | ContentPageHeaderNavItemType
      | CategoryType
      | any
  ) => void;
}

const HeaderMobileMenuLink: FC<Props> = ({
  isContactLink,
  link,
  onClick,
}: Props) => {
  const overlayDispatch = useContext(OverlayDispatchContext);
  const { colors } = getTailwindConfig().theme;
  const hasChildLinks =
    (link as ContentPageHeaderProductNavType)?.categoryItems?.length ||
    (link as ContentPageHeaderNavItemType)?.childNavigationItems?.length ||
    (link as CategoryType)?.childCategoryItems?.length;
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  return (
    <Ripples
      className="rounded-lg w-full lg:w-min"
      color={`rgba(${colors['french-blue'].rgb}, .4)`}
      during={1200}
    >
      {hasChildLinks && (
        <button
          className="mobile-menu-swiper-navigation-next flex items-center justify-between focus:outline-none px-4 py-2 w-full"
          onClick={() => onClick(link)}
          type="button"
        >
          <span className="text-left text-lg">
            {(link as ContentPageHeaderNavItemType)?.link?.name ||
              (link as CategoryType)?.name ||
              'Products'}
          </span>

          <IcomoonIcon
            className="ml-2"
            color="grey-200"
            name="chevron-right"
            size="sm"
          />
        </button>
      )}

      {!hasChildLinks && (
        <Link
          href={
            (link as ContentPageHeaderNavItemType)?.link?.url ||
            (link as CategoryType)?.url
          }
          passHref
        >
          <a
            className="mobile-menu-swiper-navigation-next flex items-center justify-between focus:outline-none px-4 py-2 w-full"
            href="replace"
            onClick={() => {
              overlayDispatch(close());

              pushToDataLayer(
                isContactLink
                  ? GtmEventNameEnum.NavContact
                  : GtmEventNameEnum.NavSubMenu
              );
            }}
            target={(link as ContentPageHeaderNavItemType)?.link?.target}
          >
            <span className="text-lg">
              {(link as ContentPageHeaderNavItemType)?.link?.name ||
                (link as CategoryType)?.name}
            </span>
          </a>
        </Link>
      )}
    </Ripples>
  );
};

HeaderMobileMenuLink.defaultProps = {
  isContactLink: false,
};

export default HeaderMobileMenuLink;
