import client from '../../../client-unauthenticated';
import CultureCodeEnum from '../../../../../models/culture-code-enum';
import { BasketType } from '../../../../../models/basket-type';

export default async function addLineItme(
  catalogId: string,
  session: string,
  basketId: string,
  variantSku: string,
  quantity: number
): Promise<BasketType> {
  const { data } = await client.post<BasketType>(
    '/basket/guest/add-line-item',
    {
      cultureCode: CultureCodeEnum.EnGb,
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      provider: 0,
      catalogId,
      session,
      quantity,
      basketId,
      variantSku,
      addToExistingLine: true,
    }
  );

  return data;
}
