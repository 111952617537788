import Action from '../../../../types/action';
import BasketActions from '.';

const removeQuote = (): Action<BasketActions> => {
  return {
    type: BasketActions.RemoveQuote,
  };
};

export default removeQuote;
