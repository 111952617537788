import client from '../../../client';
import CultureCodeEnum from '../../../../../models/culture-code-enum';
import { BasketType } from '../../../../../models/basket-type';

export default async function clear(
  session: string,
  basketId: string
): Promise<BasketType> {
  const { data } = await client.get<BasketType>('/basket/member/clear', {
    params: {
      cultureCode: CultureCodeEnum.EnGb,
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      provider: 0,
      session,
      basketId,
    },
  });

  return data;
}
