import NextImage, { ImageProps } from 'next/image';
import React, { FC } from 'react';
import useImage from '../../../hooks/use-image/use-image';
import useNextImageLoader from '../../../hooks/use-next-image-loader/use-next-image-loader';
import { ImageType } from '../../../models/image-type';

type Props = Omit<ImageProps & { model: ImageType }, 'src'>;

const Image: FC<Props> = ({ model, ...props }: Props) => {
  const selectedImage = useImage(model);

  return (
    <div className="relative w-full pb-ratio-16-9">
      <NextImage
        alt={props.alt}
        className={props.className}
        layout="fill"
        loader={useNextImageLoader}
        objectFit="cover"
        priority={props.priority}
        src={selectedImage}
      />
    </div>
  );
};

export default Image;
