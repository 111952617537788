import Link from 'next/link';
import React, {
  createRef,
  FC,
  MouseEvent,
  RefObject,
  useContext,
  useRef,
  useState,
} from 'react';
import useIsTouchDevice from '../../../hooks/use-is-touch-device/use-is-touch-device';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { CategoryType } from '../../../models/category-type';
import { ContentPageHeaderNavItemType } from '../../../models/content-page-header-nav-item-type';
import { ContentPageHeaderNavType } from '../../../models/content-page-header-nav-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import AnimationToggleRotate from '../../animations/animation-toggle-rotate/animation-toggle-rotate';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import Layout from '../../layout/layout';
import close from '../../providers/overlay/close.overlay.actions';
import open from '../../providers/overlay/open.overlay.actions';
import {
  OverlayDispatchContext,
  OverlayStateContext,
} from '../../providers/overlay/overlay.provider';
import HeaderMegaMenu from '../header-mega-menu/header-mega-menu';

const HeaderNavigation: FC<Partial<ContentPageHeaderNavType>> = ({
  navigationData = [],
  productNavigationData,
}: ContentPageHeaderNavType) => {
  const [selectedNavItem, setSelectedNavItem] = useState<
    ContentPageHeaderNavItemType | CategoryType[]
  >();
  const isTouchDevice = useIsTouchDevice();
  const overlayDispatch = useContext(OverlayDispatchContext);
  const overlayState = useContext(OverlayStateContext);
  const linkRefs = useRef<RefObject<HTMLLIElement>[]>([]);
  linkRefs.current = [
    ...[linkRefs.current[0] ?? createRef()],
    ...navigationData.map(
      (navItem: ContentPageHeaderNavItemType, index: number) =>
        (linkRefs.current[index + 1] ?? createRef()) as RefObject<HTMLLIElement>
    ),
  ];
  const categories = productNavigationData?.categoryItems || [];
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  const handleFocus = (
    navItem: ContentPageHeaderNavItemType | CategoryType[]
  ): void => {
    setSelectedNavItem(navItem);

    if (
      !(navItem as ContentPageHeaderNavItemType)?.childNavigationItems
        ?.length &&
      !(navItem as CategoryType[])?.length
    ) {
      return;
    }

    const productCtas = productNavigationData
      ? [
          productNavigationData.topRightCta,
          productNavigationData.bottomRightCta,
        ]
      : [];

    const ctas = (navItem as CategoryType[])?.length
      ? productCtas
      : (navItem as ContentPageHeaderNavItemType).navigationCtas;

    overlayDispatch(
      open(
        <HeaderMegaMenu
          bottomCta={
            (navItem as CategoryType[])?.length &&
            productNavigationData.bottomLeftCta
          }
          links={
            (navItem as ContentPageHeaderNavItemType)?.childNavigationItems ||
            (navItem as CategoryType[])
          }
          rightCtas={ctas}
          selectedNavText={
            (navItem as ContentPageHeaderNavItemType)?.link?.name ?? 'Products'
          }
          key="header-mega-menu"
        />,
        true,
        false,
        false
      )
    );
  };

  const handleBlur = (): void => {
    setSelectedNavItem(null);
    overlayDispatch(close());
  };

  if (!overlayState.components.length && selectedNavItem) {
    setTimeout(() => handleBlur(), 100);
  }

  const handleTouchClick = (
    event: MouseEvent,
    navItem: ContentPageHeaderNavItemType | CategoryType[]
  ): void => {
    pushToDataLayer(GtmEventNameEnum.MainNav);

    if (navItem === selectedNavItem) {
      handleBlur();
      return;
    }

    if (!isTouchDevice) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    handleFocus(navItem);
  };

  return (
    <div className="bg-white hidden lg:block py-3 shadow-sm">
      <Layout>
        <nav>
          <ul id="header-navigation-list" className="flex justify-center">
            <li ref={linkRefs.current[0]}>
              <button
                className="flex items-center focus:outline-none px-5"
                onClick={(event: MouseEvent) =>
                  handleTouchClick(event, categories)
                }
                onFocus={() => !isTouchDevice && handleFocus(categories)}
                onMouseOver={() => !isTouchDevice && handleFocus(categories)}
                type="button"
              >
                <span className="mr-2">Products</span>

                <AnimationToggleRotate
                  isRotated={categories === selectedNavItem}
                >
                  <IcomoonIcon
                    color="french-blue"
                    name="chevron-down"
                    size="xs"
                  />
                </AnimationToggleRotate>
              </button>
            </li>

            {navigationData.map(
              (navItem: ContentPageHeaderNavItemType, index: number) => (
                <li ref={linkRefs.current[index + 1]} key={navItem.link.url}>
                  <Link href={navItem.link.url} passHref>
                    <a
                      className="flex items-center mx-5"
                      href="replace"
                      onClick={(event: MouseEvent) =>
                        handleTouchClick(event, navItem)
                      }
                      onFocus={() => !isTouchDevice && handleFocus(navItem)}
                      onMouseOver={() => !isTouchDevice && handleFocus(navItem)}
                      target={navItem.link.target}
                    >
                      <span
                        className={
                          navItem.childNavigationItems?.length ? 'mr-2 ' : ''
                        }
                      >
                        {navItem.link.name}
                      </span>
                      {navItem.childNavigationItems?.length && (
                        <AnimationToggleRotate
                          isRotated={navItem === selectedNavItem}
                        >
                          <IcomoonIcon
                            color="french-blue"
                            name="chevron-down"
                            size="xs"
                          />
                        </AnimationToggleRotate>
                      )}
                    </a>
                  </Link>
                </li>
              )
            )}
          </ul>
        </nav>
      </Layout>
    </div>
  );
};

export default HeaderNavigation;
