import CultureCodeEnum from '../../../../models/culture-code-enum';
import { HeaderSearchResultType } from '../../../../models/header-search-result-type';
import { SearchRequestType } from '../../../../models/search-request-type';
import client from '../../client-unauthenticated';

const searchHeader = async (
  params: SearchRequestType
): Promise<HeaderSearchResultType> => {
  const { data } = await client.post<HeaderSearchResultType>('/search/header', {
    cultureCode: CultureCodeEnum.EnGb,
    domain: process.env.NEXT_PUBLIC_DOMAIN,
    featuredAmount: 1,
    filters: {},
    pageSize: 6,
    query: '',
    ...params,
  });

  return data;
};

export default searchHeader;
