import { useEffect, useState } from 'react';

type Size = {
  width: number;
  height: number;
};

export default function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: null,
    height: null,
  });

  useEffect(() => {
    const onResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', onResize);

    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return windowSize;
}
