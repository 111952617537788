import CultureCodeEnum from '../../../../../models/culture-code-enum';
import client from '../../../client';
import { ProductBasketLineItemAvailabilityType } from '../../../../../models/product-basket-line-item-availability-type';

export default async function availability(
  sessionId: string,
  basketId: string,
  productNumber: string,
  quantityRequired: number
): Promise<ProductBasketLineItemAvailabilityType[]> {
  const {
    data: { productAvailability },
  } = await client.post<{
    productAvailability: ProductBasketLineItemAvailabilityType[];
  }>(
    '/basket/stock/availability',
    { provider: 0, sessionId, productNumber, quantityRequired },
    {
      params: {
        cultureCode: CultureCodeEnum.EnGb,
        domain: process.env.NEXT_PUBLIC_DOMAIN,
        basketId,
      },
    }
  );

  return productAvailability;
}
