enum ComponentEnum {
  AccountBenefits = 'accountBenefitsComponent',
  AccountNavigation = 'accountNavigationComponent',
  AccountSubNavigation = 'accountSubNavigationComponent',
  AppDownloadCallToAction = 'appDownloadCallToActionComponent',
  BasketDetails = 'basketDetailsComponent',
  BrandListing = 'brandListingComponent',
  Breadcrumb = 'breadcrumb',
  BulkOrdering = 'bulkOrderingComponent',
  CallToActionRowWithIcons = 'callToActionRowWithIconsComponent',
  CategoriesCarousel = 'categoriesCarouselComponent',
  CategoryLandingHeading = 'categoryLandingHeadingComponent',
  Checkout = 'checkoutComponent',
  ChildCategoryPanels = 'childCategoryPanelsComponent',
  ContactDetails = 'contactDetailsComponent',
  CookiePolicy = 'cookiePolicy',
  CreditAccountDetails = 'creditAccountDetailsComponent',
  DeliveryAddresses = 'deliveryAddressesComponent',
  DespatchNoteRequest = 'despatchNoteRequest',
  DiscountStructure = 'discountStructureComponent',
  DoublePanels = 'doublePanels',
  ExtraInformationSignposts = 'extraInformationSignpostsComponent',
  FavouriteBaskets = 'favouriteBasketsComponent',
  FeaturedBrandsCarousel = 'featuredBrandsCarouselComponent',
  FeaturedBrandsPanels = 'featuredBrandsPanelsComponent',
  Footer = 'footer',
  ForgottenDetails = 'forgottenDetailsComponent',
  Form = 'formComponent',
  FreshRelevanceSmartBlock = 'freshRelevanceSmartBlock',
  HeadingWithCta = 'headingWithCtaComponent',
  HeroBanner = 'heroBannerComponent',
  HeroImageAndTextBanner = 'heroImageAndTextBannerComponent',
  HoseBuilder = 'hoseBuilderComponent',
  InlineCategoryBlock = 'inlineCategoryBlockComponent',
  InlineDownloadBlock = 'inlineDownloadBlockComponent',
  InlineFaqs = 'inlineFaqsComponent',
  InlineImage = 'inlineImageComponent',
  InlineVideo = 'inlineVideoComponent',
  InvoiceRequest = 'invoiceRequest',
  LatestCatalogueBlockContent = 'latestCatalogueBlockContentComponent',
  LatestNewsListing = 'latestNewsListingComponent',
  LatestOrders = 'latestOrdersComponent',
  Login = 'loginComponent',
  MapAndDirections = 'mapAndDirectionsComponent',
  MyDetailsForm = 'myDetailsFormComponent',
  NewsListing = 'newsListingComponent',
  OrderDetails = 'orderDetailsComponent',
  OrdersListing = 'ordersListingComponent',
  ProductDetails = 'productDetailsComponent',
  ProductListingWithFilters = 'productListingWithFiltersComponent',
  PromoPanel = 'promoPanelComponent',
  QuickAddTool = 'quickAddToolComponent',
  QuickAddMultiTool = 'quickAddMultiTool',
  Quote = 'quoteComponent',
  Redirect = 'redirectComponent',
  Registration = 'registrationComponent',
  ResetPassword = 'resetPasswordComponent',
  Resources = 'resourcesComponent',
  RetrieveQuote = 'retrieveQuote',
  RichText = 'richTextComponent',
  SaveAsFavouritesBasket = 'saveAsFavouritesBasketComponent',
  SearchResults = 'searchResultsComponent',
  SignpostPanels = 'signpostPanelsComponent',
  SocialMediaLinksWithText = 'socialMediaLinksWithTextComponent',
  SubAccountRegistration = 'subAccountRegistration',
  SubtotalDeductions = 'subtotalDeductionsComponent',
  TechnicalDatasheetsBlockContent = 'technicalDatasheetsBlockContentComponent',
  UpdateDetailsBlockContent = 'updateDetailsBlockContentComponent',
  UserManagement = 'userManagement',
  YouRecentlyViewed = 'youRecentlyViewedComponent',
  UspListBasket = 'uniqueSellingPointsComponent',
}

export default ComponentEnum;
