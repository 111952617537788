import React, { FC, ReactNode, useState } from 'react';
import AnimationToggleScale from '../../animations/animation-toggle-scale/animation-toggle-scale';

interface Props {
  readonly children: ReactNode;
  readonly enabled?: boolean;
  readonly label?: string;
}

const TextTooltip: FC<Props> = ({ children, enabled, label }: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
    >
      {children}
      <AnimationToggleScale isActive={label && enabled && isActive}>
        <div
          className={
            `${label.length > 25 ? 'w-80' : 'w-auto'} ` +
            'absolute left-1/2 top-full mt-2 pointer-events-none'
          }
        >
          <div className="absolute -left-2 -top-1 w-4 h-4 transform rotate-45 bg-black" />
          <div className="relative -left-1/2 bg-black text-white px-4 py-2 text-sm rounded text-center">
            {label}
          </div>
        </div>
      </AnimationToggleScale>
    </div>
  );
};

TextTooltip.defaultProps = {
  label: null,
  enabled: true,
};

export default TextTooltip;
