import React, { FC } from 'react';

interface Props {
  readonly name: string;
}

const ComponentRenderError: FC<Props> = ({ name }: Props) => {
  if (process.env.NEXT_PUBLIC_PRODUCTION === 'true') {
    return null;
  }

  return (
    <p className="text-center text-invalid truncate py-8">
      <span>Component&nbsp;</span>
      <span className="font-bold italic">{name}&nbsp;</span>
      <span>could not be rendered.</span>
    </p>
  );
};

export default ComponentRenderError;
