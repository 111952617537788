import { useContext } from 'react';
import { UserType } from '../../../../models/user-type';
import { UserStateContext } from '../user.provider';

const useUser = (): UserType | null => {
  const { user } = useContext(UserStateContext);

  return user;
};

export default useUser;
