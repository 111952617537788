import Link from 'next/link';
import React, { FC, useContext } from 'react';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { ContentPageHeaderLinkIconType } from '../../../models/content-page-header-link-icon-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import close from '../../providers/overlay/close.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';

const HeaderAccountDropdownLink: FC<ContentPageHeaderLinkIconType> = ({
  link,
  icon,
}: ContentPageHeaderLinkIconType) => {
  const overlayDispatch = useContext(OverlayDispatchContext);
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  return (
    <li className="my-6">
      <Link href={link.url} passHref>
        <a
          className="flex items-center hover:text-french-blue"
          href="replace"
          onClick={() => {
            overlayDispatch(close());

            pushToDataLayer(GtmEventNameEnum.AccountNav);
          }}
          target={link.target || '_self'}
        >
          <IcomoonIcon
            className="mr-4"
            color="french-blue"
            name={icon}
            size="md"
          />
          <span className="mb-2px text-left">{link.name}</span>
        </a>
      </Link>
    </li>
  );
};

export default HeaderAccountDropdownLink;
