import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import styles from './rich-text.module.scss';

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: string;
  spacing?: 'full' | 'half';
}

const RichText: FC<Props> = ({
  children,
  spacing,
  className,
  ...props
}: Props) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: children }}
      className={`
        ${styles['rich-text']}
        ${spacing === 'half' && styles['rich-text-spacing-half']}
        ${className || ''}
      `}
      {...props}
    />
  );
};

RichText.defaultProps = {
  spacing: 'full',
};

export default RichText;
