import { motion } from 'framer-motion';
import React, { FC } from 'react';

interface Props {
  readonly heading: string;
  readonly message?: string;
}

const NoDataBlock: FC<Props> = ({ heading, message }: Props) => (
  <div className="bg-grey-50 px-8 py-24 rounded-lg">
    <div className="flex flex-col items-center mx-auto text-center max-w-md">
      <motion.h4
        animate={{ opacity: 1 }}
        className="page-heading-four"
        initial={{ opacity: 0 }}
      >
        {heading}
      </motion.h4>

      {message && (
        <motion.p
          animate={{ opacity: 1 }}
          className="mt-4 text-grey-600"
          initial={{ opacity: 0 }}
        >
          {message}
        </motion.p>
      )}
    </div>
  </div>
);

NoDataBlock.defaultProps = {
  message: '',
};

export default NoDataBlock;
