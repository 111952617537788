import Link from 'next/link';
import React, { FC, FocusEventHandler, MouseEventHandler } from 'react';
import { LinkType } from '../../../models/link-type';
import IcomoonIconButton from '../../atoms/icomoon-icon-button/icomoon-icon-button';

interface Props {
  readonly className?: string;
  readonly icon: string;
  readonly id?: string;
  readonly isLabelBold?: boolean;
  readonly itemCount?: number | null;
  readonly label: string;
  readonly link?: LinkType;
  readonly onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  readonly onFocus?: FocusEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  readonly onMouseOver?: MouseEventHandler<
    HTMLAnchorElement | HTMLButtonElement
  >;
  readonly showLabelMobile?: boolean;
}

const HeaderIconButton: FC<Props> = ({
  className,
  icon,
  id,
  isLabelBold,
  itemCount,
  label,
  link,
  onClick,
  onFocus,
  onMouseOver,
  showLabelMobile,
}) => (
  <div
    className={`${className} flex flex-row lg:flex-col items-center mx-0 lg:mx-2 xl:mx-3 relative`}
    id={id}
  >
    <IcomoonIconButton
      color="french-blue"
      name={icon}
      onClick={onClick}
      onFocus={onFocus}
      onMouseOver={onMouseOver}
      target={link?.target}
      url={link?.url}
      ariaLabel={label || 'Header button'}
    />

    {!!itemCount && (
      <div className="absolute bg-french-blue border border-white flex items-center justify-center right-0 rounded-full top-0">
        <span className="px-2 text-xs text-white">{itemCount}</span>
      </div>
    )}

    {link && (
      <Link href={link?.url} passHref>
        <a
          className="relative"
          href="replace"
          onClick={onClick}
          onFocus={onFocus}
          onMouseOver={onMouseOver}
          target={link?.target}
          aria-label={label || 'Header link'}
        >
          <span
            className={
              `${isLabelBold ? 'font-bold ' : ''}` +
              `${showLabelMobile ? '' : 'hidden lg:block '}` +
              'duration-150 text-sm text-grey-600 hover:text-french-blue transition-colors'
            }
          >
            {label}
          </span>
        </a>
      </Link>
    )}

    {!link && (
      <button
        onClick={onClick}
        onFocus={onFocus}
        onMouseOver={onMouseOver}
        type="button"
        aria-label={label || 'Header link'}
      >
        <span
          className={
            `${isLabelBold ? 'font-bold ' : ''}` +
            `${showLabelMobile ? '' : 'hidden lg:block '}` +
            'duration-150 text-sm text-grey-600 hover:text-french-blue transition-colors'
          }
        >
          {label}
        </span>
      </button>
    )}
  </div>
);

HeaderIconButton.defaultProps = {
  className: '',
  id: null,
  isLabelBold: false,
  itemCount: null,
  link: null,
  onClick: null,
  onFocus: null,
  onMouseOver: null,
  showLabelMobile: false,
};

export default HeaderIconButton;
