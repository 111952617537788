import React, { FC, useContext } from 'react';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { ContentPageHeaderCtaType } from '../../../models/content-page-header-cta-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import TextLink from '../../atoms/text-link/text-link';

const HeaderMegaMenuCta: FC<ContentPageHeaderCtaType> = ({
  description,
  heading,
  link,
}: ContentPageHeaderCtaType) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  return (
    <div>
      {heading && <h6 className="page-heading-six mb-2">{heading}</h6>}
      {description && <p className="text-grey-600 mb-2">{description}</p>}
      {link && (
        <TextLink
          {...link}
          showIcon
          onClick={() =>
            pushToDataLayer(GtmEventNameEnum.NavSubMenuCard, {
              cardTitle: heading,
            })
          }
        />
      )}
    </div>
  );
};

export default HeaderMegaMenuCta;
