import { Field, Form, Formik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useContext, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { CategoryType } from '../../../models/category-type';
import { ContentPageHeaderSearchType } from '../../../models/content-page-header-search-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import AnimationToggleRotate from '../../animations/animation-toggle-rotate/animation-toggle-rotate';
import IcomoonIconButton from '../../atoms/icomoon-icon-button/icomoon-icon-button';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import LoadingSpinner from '../../atoms/loading-spinner/loading-spinner';
import close from '../../providers/overlay/close.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';

interface Props extends ContentPageHeaderSearchType {
  readonly isSearching: boolean;
  readonly onSearchStatusChange: (isSearching: boolean) => void;
  readonly onSearchChange: ({
    searchTerm: string,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    selectedCategory: CategoryType,
  }) => void;
  readonly onSearchSubmit: ({
    searchTerm: string,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    selectedCategory: CategoryType,
  }) => void;
}

const HeaderSearchTool: FC<Props> = ({
  headerSearchLabels,
  isSearching,
  onSearchStatusChange,
  onSearchChange,
  onSearchSubmit,
  productCategoryData,
}: Props) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const overlayDispatch = useContext(OverlayDispatchContext);
  const [debounce, setDebounce] = useState<NodeJS.Timeout>(null);
  const [previousSearchTerm, setPreviousSearchTerm] = useState<string>('');
  const [showCategories, setShowCategories] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<CategoryType>(
    productCategoryData?.[0]
  );
  const searchTermInputRef = useRef<HTMLInputElement>(null);

  const onChange = (searchTerm: string) => {
    setShowCategories(false);
    onSearchStatusChange(true);

    if (debounce) {
      clearTimeout(debounce);
    }

    if (!searchTerm) {
      overlayDispatch(close());
      onSearchStatusChange(false);

      return;
    }

    if (searchTerm === previousSearchTerm) {
      onSearchChange({ searchTerm, selectedCategory });
      setPreviousSearchTerm(searchTerm);
      onSearchStatusChange(false);

      return;
    }

    setPreviousSearchTerm(searchTerm);

    if (searchTerm.length < 3) {
      overlayDispatch(close());
      onSearchStatusChange(false);

      return;
    }

    setDebounce(
      setTimeout(() => {
        onSearchChange({ searchTerm, selectedCategory });
      }, 250)
    );
  };

  const pushSearchStart = () => pushToDataLayer(GtmEventNameEnum.SearchStart);

  return (
    <Formik
      initialValues={{
        searchTerm: '',
      }}
      onSubmit={(values) => onSearchSubmit({ ...values, selectedCategory })}
      validationSchema={yup.object().shape({
        searchTerm: yup.string(),
      })}
    >
      {({ handleChange, setFieldValue, submitForm }) => (
        <Form autoComplete="off">
          <div className="flex relative">
            <div>
              <button
                className="bg-light-blue-dark flex h-full items-center focus:outline-none px-6 rounded-l-md"
                onClick={() => {
                  overlayDispatch(close());
                  setShowCategories(!showCategories);

                  if (!showCategories) {
                    pushSearchStart();
                  }
                }}
                type="button"
              >
                <span className="text-left text-sm truncate w-12 md:w-32 lg:w-24 xl:w-full">
                  {selectedCategory?.name}
                </span>
                <span className="ml-4">
                  <AnimationToggleRotate isRotated={showCategories}>
                    <IcomoonIcon
                      name="chevron-down"
                      color="french-blue"
                      size="base"
                    />
                  </AnimationToggleRotate>
                </span>
              </button>

              <AnimatePresence>
                {showCategories && (
                  <motion.div
                    animate={{ opacity: 1, y: 0 }}
                    className="absolute top-full left-0 right-0 bg-white shadow-md p-1 rounded-b-md flex flex-col max-h-64 overflow-y-auto overflow-x-hidden z-40"
                    exit={{ opacity: 0, y: 0 }}
                    initial={{ opacity: 0, y: -20 }}
                  >
                    {productCategoryData.map((category: CategoryType) => (
                      <button
                        key={category.categoryId}
                        type="button"
                        className="px-6 py-3 rounded-md text-left hover:bg-french-blue hover:text-white"
                        onClick={() => {
                          setShowCategories(false);
                          setSelectedCategory(category);
                          setFieldValue('searchTerm', '');
                          searchTermInputRef.current?.focus();
                        }}
                      >
                        {category.name}
                      </button>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <Field
              innerRef={searchTermInputRef}
              className="bg-light-blue outline-none pl-6 pr-20 py-4 placeholder-grey-600 ring-focus focus:ring-1 rounded-r-md text-sm w-full"
              name="searchTerm"
              onChange={(event) => {
                handleChange(event);
                onChange(event.target.value);
              }}
              onFocus={() => pushSearchStart()}
              placeholder={headerSearchLabels?.searchBoxPlaceholderText}
              type="search"
            />

            {!isSearching && (
              <div className="absolute flex items-center h-full right-2 top-0">
                <IcomoonIconButton
                  name="search"
                  size="lg"
                  onClick={() => submitForm()}
                />
              </div>
            )}

            {isSearching && (
              <div className="absolute flex items-center h-full right-5 top-0">
                <LoadingSpinner className="-mb-2" />
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default HeaderSearchTool;
