import { useContext } from 'react';
import createAsyncAlertModal from '../../../../helpers/create-async-alert-modal';
import { BasketClearQuoteLabelsType } from '../../../../models/basket-clear-quote-labels-type';
import { BasketType } from '../../../../models/basket-type';
import guestClear from '../../../../services/content-api/endpoints/basket/guest/clear';
import memberClear from '../../../../services/content-api/endpoints/basket/member/clear';
import { OverlayDispatchContext } from '../../overlay/overlay.provider';
import useGetSessionId from '../../user/hooks/use-get-session-id.hook';
import { UserStateContext } from '../../user/user.provider';
import basketLoading from '../actions/basket-loading';
import updateBasket from '../actions/update-basket';
import { BasketDispatchContext, BasketStateContext } from '../basket.provider';
import useDeselectQuote from './use-deselect-quote';

const useClearBasket: (
  clearQuoteLabels: BasketClearQuoteLabelsType,
  options?: { ignoreClearQuoteAlert: boolean }
) => () => Promise<BasketType> = (
  clearQuoteLabels: BasketClearQuoteLabelsType,
  { ignoreClearQuoteAlert } = { ignoreClearQuoteAlert: false }
) => {
  const { auth } = useContext(UserStateContext);
  const basketDispatch = useContext(BasketDispatchContext);
  const basketState = useContext(BasketStateContext);
  const overlayDispatch = useContext(OverlayDispatchContext);
  const getSessionId = useGetSessionId();
  const deselectQuote = useDeselectQuote();

  return async () => {
    basketDispatch(basketLoading(true));

    let { basket } = basketState;

    try {
      if (basketState.quote && !ignoreClearQuoteAlert) {
        await createAsyncAlertModal(
          overlayDispatch,
          clearQuoteLabels.clearQuoteHeading,
          clearQuoteLabels.clearQuoteDescription,
          clearQuoteLabels.clearQuoteAccept,
          clearQuoteLabels.clearQuoteCancel,
          clearQuoteLabels.clearQuoteHelp
        );

        await deselectQuote();
      }
    } catch (error) {
      basketDispatch(basketLoading(false));

      throw error;
    }

    try {
      basket = auth.accessToken
        ? await memberClear(await getSessionId(), basketState.id)
        : await guestClear(await getSessionId(), basketState.id);
    } catch (error) {
      basketDispatch(basketLoading(false));
      throw error;
    }

    basketDispatch(updateBasket(basket));
    basketDispatch(basketLoading(false));

    return basket;
  };
};

export default useClearBasket;
