import { useEffect, useState } from 'react';
import BreakpointEnum from '../../models/breakpoint-enum';
import getTailwindConfig from '../../helpers/get-tailwind-config';

export const checkBreakpoints = (screens: {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  '2xl': string;
}): BreakpointEnum => {
  const windowWidth = typeof window !== `undefined` ? window.innerWidth : null;

  if (windowWidth >= parseInt(screens['2xl'], 10)) {
    return BreakpointEnum.xxl;
  }

  if (windowWidth >= parseInt(screens.xl, 10)) {
    return BreakpointEnum.xl;
  }

  if (windowWidth >= parseInt(screens.lg, 10)) {
    return BreakpointEnum.lg;
  }

  if (windowWidth >= parseInt(screens.md, 10)) {
    return BreakpointEnum.md;
  }

  return BreakpointEnum.sm;
};

const useIsBreakpoint = (): BreakpointEnum => {
  const { screens } = getTailwindConfig().theme;
  const [breakpoint, setBreakpoint] = useState<BreakpointEnum>(
    checkBreakpoints(screens)
  );

  useEffect(() => {
    const handleChangeSize = () => {
      const newBreakpoint = checkBreakpoints(screens);

      setBreakpoint(newBreakpoint);
    };

    window.addEventListener('resize', handleChangeSize);

    return () => {
      window.removeEventListener('resize', handleChangeSize);
    };
  }, [screens, breakpoint]);

  return breakpoint;
};

export default useIsBreakpoint;
