import Action from '../../../../types/action';
import BasketActions from '.';

const reset = (): Action<BasketActions> => {
  return {
    type: BasketActions.Reset,
  };
};

export default reset;
