import { useContext } from 'react';
import { BasketDispatchContext, BasketStateContext } from '../basket.provider';
import useGetSessionId from '../../user/hooks/use-get-session-id.hook';
import orderMemberQuoteDeselect from '../../../../services/content-api/endpoints/order/member/quote/deselect';
import removeQuote from '../actions/remove-quote';
import invalidateBasket from '../actions/invalidate-basket';

const useDeselectQuote: () => () => Promise<void> = () => {
  const basketDispatch = useContext(BasketDispatchContext);
  const basketState = useContext(BasketStateContext);
  const getSessionId = useGetSessionId();

  return async () => {
    if (basketState.quote?.id) {
      await orderMemberQuoteDeselect(
        basketState.quote.id,
        await getSessionId()
      );

      basketDispatch(invalidateBasket());
    }

    basketDispatch(removeQuote());
  };
};

export default useDeselectQuote;
