import BasketActions from '.';
import Action from '../../../../types/action';

const setLastAddedVariantSkus = (
  variantSkus: string[]
): Action<BasketActions> => {
  return {
    type: BasketActions.SetLastAddedVariantSkus,
    payload: variantSkus,
  };
};

export default setLastAddedVariantSkus;
