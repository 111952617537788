import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import React, { FC, useState, useContext } from 'react';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import LocalStorageKeysEnum from '../../../models/local-storage-keys-enum';
import IcomoonIconButton from '../../atoms/icomoon-icon-button/icomoon-icon-button';
import Layout from '../../layout/layout';
import styles from './header-site-message.module.scss';

interface Props {
  readonly siteMessageContent: string;
}

const HeaderSiteMessage: FC<Props> = ({ siteMessageContent }: Props) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const [isDismissed, setIsDismissed] = useState<boolean>(false);

  const handleDismiss = (): void => {
    setIsDismissed(true);

    localStorage.setItem(
      LocalStorageKeysEnum.SiteMessageDismissedDate,
      dayjs(new Date()).format()
    );

    pushToDataLayer(GtmEventNameEnum.BannerClose);
  };

  if (!siteMessageContent?.length) {
    return null;
  }

  return (
    <motion.div
      animate={isDismissed ? 'isDismissed' : 'notDismissed'}
      className="bg-french-blue -mt-1px overflow-hidden sticky text-center text-white top-header-mobile lg:top-header z-35"
      id="main-nav-site-message"
      variants={{
        isDismissed: { height: 0, opacity: 0.4 },
        notDismissed: { height: 'initial', opacity: 1 },
      }}
      transition={{
        delay: 0.3,
        duration: 0.3,
      }}
    >
      <Layout>
        <div className="flex items-center justify-center py-3 relative">
          <span
            className={styles['site-message-content']}
            dangerouslySetInnerHTML={{ __html: siteMessageContent }}
          />

          <div className="absolute right-0">
            <IcomoonIconButton
              color="white"
              name="close"
              onClick={() => handleDismiss()}
              size="md"
            />
          </div>
        </div>
      </Layout>
    </motion.div>
  );
};

export default HeaderSiteMessage;
