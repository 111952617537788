import { AnimatePresence, motion } from 'framer-motion';
import React, {
  FC,
  MouseEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useIsTouchDevice from '../../../hooks/use-is-touch-device/use-is-touch-device';
import { CategoryType } from '../../../models/category-type';
import { ContentPageHeaderCtaType } from '../../../models/content-page-header-cta-type';
import { ContentPageHeaderNavItemType } from '../../../models/content-page-header-nav-item-type';
import close from '../../providers/overlay/close.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import HeaderMegaMenuCta from '../header-mega-menu-cta/header-mega-menu-cta';
import HeaderMegaMenuLinks from '../header-mega-menu-links/header-mega-menu-links';

interface Props {
  readonly bottomCta?: ContentPageHeaderCtaType;
  readonly links: ContentPageHeaderNavItemType[] | CategoryType[];
  readonly rightCtas?: ContentPageHeaderCtaType[];
  readonly selectedNavText: string;
}

const HeaderMegaMenu: FC<Props> = ({
  bottomCta,
  links,
  rightCtas,
  selectedNavText,
}: Props) => {
  const [animationPerformed, setAnimationPerformed] = useState<boolean>(false);
  const [contentShown, setContentShown] = useState<number>(null);
  const [page, setPage] = useState<number>(0);
  const [selectedLink, setSelectedLink] = useState<
    ContentPageHeaderNavItemType | CategoryType
  >(null);
  const [selectedSecondaryLink, setSelectedSecondaryLink] = useState<
    ContentPageHeaderNavItemType | CategoryType
  >(null);
  const [selectedTertiaryLink, setSelectedTertiaryLink] = useState<
    ContentPageHeaderNavItemType | CategoryType
  >(null);
  const columnRef = useRef(null);
  const isTouchDevice = useIsTouchDevice();

  const overlayDispatch = useContext(OverlayDispatchContext);

  const springTransition = {
    damping: 24,
    duration: 0,
    mass: 0.8,
    stiffness: 200,
    type: 'spring',
  };

  const hasChildren = (
    link: ContentPageHeaderNavItemType | CategoryType
  ): boolean =>
    !!(link as ContentPageHeaderNavItemType)?.childNavigationItems?.length ||
    !!(link as CategoryType)?.childCategoryItems?.length;

  useEffect(() => {
    setSelectedLink(null);
    setSelectedSecondaryLink(null);
    setSelectedTertiaryLink(null);
    setPage(0);
    setContentShown(0);
  }, [links]);

  useEffect(() => {
    setSelectedSecondaryLink(null);
    setSelectedTertiaryLink(null);

    if (selectedLink && hasChildren(selectedLink)) {
      setPage(1);
    } else {
      setPage(0);
      setContentShown(0);
    }
  }, [selectedLink]);

  const setLink = (link: ContentPageHeaderNavItemType | CategoryType): void => {
    setSelectedLink(link);

    if (hasChildren(link)) {
      setPage(1);
    }
  };

  const setSecondaryLink = (
    link: ContentPageHeaderNavItemType | CategoryType
  ): void => {
    setSelectedSecondaryLink(link);

    if (hasChildren(link)) {
      setPage(2);
    }
  };

  const handleTouchClick = (
    event: MouseEvent,
    link: ContentPageHeaderNavItemType | CategoryType,
    isSecondary: boolean
  ): void => {
    if (
      !isTouchDevice ||
      (!isSecondary && link === selectedLink) ||
      (isSecondary && link === selectedSecondaryLink) ||
      (!(link as ContentPageHeaderNavItemType)?.childNavigationItems?.length &&
        !(link as CategoryType)?.childCategoryItems?.length)
    ) {
      overlayDispatch(close());

      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (!isSecondary) {
      setLink(link);

      return;
    }

    setSecondaryLink(link);
  };

  const CtasComponent = (
    <motion.div
      key="ctas-column"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.15,
        duration: 0.4,
      }}
      className="bg-grey-50 origin-right px-8 py-6 w-full h-full"
    >
      {!!rightCtas.length &&
        rightCtas.map((cta: ContentPageHeaderCtaType, index: number) => (
          <div
            key={cta.link.url}
            className={index < rightCtas.length ? 'mb-8' : ''}
          >
            <HeaderMegaMenuCta {...cta} />
          </div>
        ))}
    </motion.div>
  );

  return (
    <nav>
      <div className="relative">
        <div
          className="origin-left top-0 left-0 w-full z-50 border-t border-grey-50"
          role="presentation"
        >
          <motion.div
            animate={{
              height: `auto`,
              opacity: 1,
              y: 0,
            }}
            className="bg-white flex origin-right overflow-hidden shadow-lg"
            id="header-mega-menu"
            initial={{
              height: 0,
              opacity: animationPerformed ? 1 : 0,
              y: animationPerformed ? 0 : -20,
            }}
            onAnimationComplete={() => {
              setContentShown(page);
              setAnimationPerformed(true);
            }}
            style={{
              minHeight: '250px',
              maxHeight: '60vh',
              padding: '0 5%',
            }}
            transition={springTransition}
          >
            <AnimatePresence>
              {contentShown >= 0 && (
                <motion.div
                  ref={columnRef}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    delay: 0.15,
                    duration: 0.5,
                  }}
                  className="flex flex-col origin-right justify-between overflow-y-auto px-8 py-6"
                  style={{ width: '25%' }}
                >
                  <div>
                    <h6 className="page-heading-six text-grey-500 mb-4">
                      {selectedNavText}
                    </h6>
                    <HeaderMegaMenuLinks
                      links={links}
                      onClick={(
                        event: MouseEvent,
                        link: ContentPageHeaderNavItemType | CategoryType
                      ) => handleTouchClick(event, link, false)}
                      onFocus={(
                        link: ContentPageHeaderNavItemType | CategoryType
                      ) => !isTouchDevice && setLink(link)}
                      onMouseOver={(
                        link: ContentPageHeaderNavItemType | CategoryType
                      ) => !isTouchDevice && setLink(link)}
                      selectedLink={selectedLink}
                    />
                  </div>

                  {bottomCta && (
                    <div>
                      <hr className="border-grey-100 mb-4 mt-20" />
                      <HeaderMegaMenuCta {...bottomCta} />
                    </div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>

            <div
              className="border-l border-grey-100 origin-right overflow-y-auto"
              style={{ width: '25%' }}
            >
              <AnimatePresence>
                {hasChildren(selectedLink) && page >= 1 && (
                  <motion.div
                    key="col-2-content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      delay: 0.15,
                      duration: 0.5,
                    }}
                    className="px-8 py-6 w-full"
                  >
                    <div>
                      <h6 className="page-heading-six text-grey-500 mb-4">
                        {(selectedLink as ContentPageHeaderNavItemType)?.link
                          ?.name ||
                          (selectedLink as CategoryType)?.name ||
                          ''}
                      </h6>
                      <HeaderMegaMenuLinks
                        links={
                          (selectedLink as ContentPageHeaderNavItemType)
                            ?.childNavigationItems ||
                          (selectedLink as CategoryType)?.childCategoryItems
                        }
                        onClick={(
                          event: MouseEvent,
                          link: ContentPageHeaderNavItemType | CategoryType
                        ) => handleTouchClick(event, link, true)}
                        onFocus={(
                          link: ContentPageHeaderNavItemType | CategoryType
                        ) => !isTouchDevice && setSecondaryLink(link)}
                        onMouseOver={(
                          link: ContentPageHeaderNavItemType | CategoryType
                        ) => !isTouchDevice && setSecondaryLink(link)}
                        selectedLink={selectedSecondaryLink}
                      />
                    </div>
                  </motion.div>
                )}
                {(!hasChildren(selectedLink) || page === 0) &&
                  !!rightCtas.length &&
                  CtasComponent}
              </AnimatePresence>
            </div>

            <div
              className="border-l border-grey-100 origin-right overflow-y-auto"
              style={{ width: '25%' }}
            >
              <AnimatePresence>
                {hasChildren(selectedSecondaryLink) && page >= 2 && (
                  <motion.div
                    key="col-3-content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      delay: 0.15,
                      duration: 0.5,
                    }}
                    className="px-8 py-6 w-full"
                  >
                    <div>
                      <h6 className="page-heading-six text-grey-500 mb-4">
                        {(selectedSecondaryLink as ContentPageHeaderNavItemType)
                          ?.link?.name ||
                          (selectedSecondaryLink as CategoryType)?.name ||
                          ''}
                      </h6>
                      <HeaderMegaMenuLinks
                        links={
                          (
                            selectedSecondaryLink as ContentPageHeaderNavItemType
                          )?.childNavigationItems ||
                          (selectedSecondaryLink as CategoryType)
                            ?.childCategoryItems
                        }
                        onClick={(
                          event: MouseEvent,
                          link: ContentPageHeaderNavItemType | CategoryType
                        ) => handleTouchClick(event, link, true)}
                        onFocus={(
                          link: ContentPageHeaderNavItemType | CategoryType
                        ) => !isTouchDevice && setSelectedTertiaryLink(link)}
                        onMouseOver={(
                          link: ContentPageHeaderNavItemType | CategoryType
                        ) => !isTouchDevice && setSelectedTertiaryLink(link)}
                        selectedLink={selectedTertiaryLink}
                      />
                    </div>
                  </motion.div>
                )}
                {!!rightCtas.length &&
                  (page === 1 ||
                    (page === 2 && !hasChildren(selectedSecondaryLink))) &&
                  CtasComponent}
              </AnimatePresence>
            </div>

            <div
              className="border-l border-grey-100 origin-right overflow-y-auto"
              style={{ width: '25%' }}
            >
              <AnimatePresence>
                {hasChildren(selectedSecondaryLink) &&
                  page === 2 &&
                  !!rightCtas.length &&
                  CtasComponent}
              </AnimatePresence>
            </div>
          </motion.div>
        </div>
      </div>
    </nav>
  );
};

HeaderMegaMenu.defaultProps = {
  bottomCta: null,
  rightCtas: [],
};

export default HeaderMegaMenu;
