enum ContentPageDocumentTypeEnum {
  AccountDashboardPage = 'accountDashboardPage',
  AccountPage = 'accountPage',
  BasketPage = 'basketPage',
  BrandsDetailPage = 'brandsDetailPage',
  CategoryLandingPage = 'categoryLandingPage',
  NewsArticlePage = 'newsArticlePage',
  ProductDetailPage = 'productDetailPage',
  ProductListingPage = 'productListingPage',
  SearchResultsPage = 'searchResultsPage',
  StandardPage = 'standardPage',
}

export default ContentPageDocumentTypeEnum;
