function findMiddleImage(properties) {
  const allMatchingProperties = Object.keys(properties).filter((key) =>
    key.startsWith('webImage')
  );

  if (allMatchingProperties.length > 0) {
    const sortedProperties = allMatchingProperties.sort((a, b) => {
      // eslint-disable-next-line radix
      const aNumber = parseInt(a.match(/\d+/)[0]);
      // eslint-disable-next-line radix
      const bNumber = parseInt(b.match(/\d+/)[0]);
      return aNumber - bNumber;
    });

    const middlePropertyIndex = Math.floor(sortedProperties.length / 2);
    const middleProperty = sortedProperties[middlePropertyIndex];

    return {
      key: middleProperty,
      source: properties[middleProperty],
    };
  }

  return {};
}

export default findMiddleImage;
