import Image, { ImageProps } from 'next/image';
import React, { FC } from 'react';
import useNextImageLoader from '../../../hooks/use-next-image-loader/use-next-image-loader';

type Props = Omit<ImageProps, 'height' & 'width'>;

const ProductImage: FC<Props> = ({ ...props }: Props) => {
  return (
    <div className="relative w-full pb-full">
      {props.src && (
        <Image
          alt={props.alt}
          title={props.title}
          className={props.className}
          layout="fill"
          loader={useNextImageLoader}
          objectFit="cover"
          quality={100}
          src={props.src}
          aria-label={props?.title ? `Image ${props.title}` : 'Regular image'}
        />
      )}
    </div>
  );
};

export default ProductImage;
