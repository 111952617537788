import { motion } from 'framer-motion';
import Link from 'next/link';
import React, { FC, useContext, useEffect, useState } from 'react';
import interpolateContent from '../../../helpers/interpolate-content';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { BrandType } from '../../../models/brand-type';
import { CategoryType } from '../../../models/category-type';
import { ContentPageHeaderSearchLabelsType } from '../../../models/content-page-header-search-labels-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { HeaderSearchResultType } from '../../../models/header-search-result-type';
import { LinkType } from '../../../models/link-type';
import { ProductLineItemType } from '../../../models/product-line-item-type';
import { SpecialPricingType } from '../../../models/special-pricing-type';
import pricingRequest from '../../../services/customer-api/endpoints/pricing/request';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import TextLink from '../../atoms/text-link/text-link';
import Layout from '../../layout/layout';
import close from '../../providers/overlay/close.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import useIsLoggedIn from '../../providers/user/hooks/use-is-logged-in';
import NoDataBlock from '../no-data-block/no-data-block';
import ProductCard from '../product-card/product-card';
import RichText from '../rich-text/rich-text';
import HeaderLoadingList from './search-loading-list';

interface Props extends HeaderSearchResultType {
  readonly labels: ContentPageHeaderSearchLabelsType;
  readonly searchPageLink: LinkType;
  readonly searchTerm?: string;
  readonly selectedCategory?: CategoryType;
  readonly isSearching: boolean;
}

const HeaderSearchDropdown: FC<Props> = ({
  brandResults,
  categoryResults,
  featuredResults,
  labels,
  results,
  searchPageLink,
  searchTerm,
  selectedCategory,
  isSearching,
}: Props) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const [hoveringBrand, setHoveringBrand] = useState<number>();
  const [hoveringCategory, setHoveringCategory] = useState<number>();
  const [specialPrices, setSpecialPrices] = useState<
    Record<string, SpecialPricingType>
  >({});
  const isLoggedIn = useIsLoggedIn();
  const overlayDispatch = useContext(OverlayDispatchContext);
  const searchTermRegex = new RegExp(searchTerm, 'gi');

  const highlightSearchTerm = (name: string) =>
    name.replace(
      searchTermRegex,
      (match: string) => `<span class="font-bold">${match}</span>`
    );

  const searchPageLinkWithQuery: LinkType = {
    ...searchPageLink,
    url: `${searchPageLink.url}?${new URLSearchParams({
      query: searchTerm,
      ...(selectedCategory?.categoryId
        ? { category: selectedCategory.categoryId }
        : {}),
      noRedirect: 'true',
    }).toString()}`,
  };

  const pushSearchSelect = () => pushToDataLayer(GtmEventNameEnum.SearchSelect);

  const pushSearchDownloadTechSheet = () =>
    pushToDataLayer(GtmEventNameEnum.SearchTechSheet);

  const pushNoResults = () =>
    pushToDataLayer(GtmEventNameEnum.SearchNoResult, { searchTerm });

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const unpricedProducts = [...featuredResults, ...results]
      .slice(0, 6)
      .filter(
        (line) => !(line.product.properties.internalRef in specialPrices)
      );

    if (unpricedProducts.length === 0) {
      return;
    }

    pricingRequest(unpricedProducts).then((response: SpecialPricingType[]) =>
      setSpecialPrices((existingPrices) => ({
        ...existingPrices,
        ...response.reduce(
          (prices, price) => ({ ...prices, [price.productId]: price }),
          {}
        ),
      }))
    );
  }, [isLoggedIn, featuredResults, results]);

  useEffect(() => {
    if (results.length === 0 && featuredResults.length === 0) {
      pushNoResults();
    }
  }, [featuredResults, results]);
  return (
    <nav>
      <motion.div
        animate={{ opacity: 1, y: 0 }}
        className="absolute bg-white shadow-inner lg:shadow-md w-full z-10"
        initial={{ opacity: 0, y: -20 }}
        transition={{
          damping: 24,
          duration: 0.6,
          mass: 0.8,
          stiffness: 200,
          type: 'spring',
        }}
      >
        <Layout>
          <div className="grid xl:grid-cols-4">
            <div className="border-b xl:border-b-0 xl:border-r border-grey-100 col-span-1 xl:col-span-3 flex flex-col space-y-6 py-8 lg:py-12 xl:pr-12">
              <div className="flex justify-between">
                <h3 className="page-heading-four text-center md:text-left">
                  {labels.resultsHeader}
                </h3>

                {!!(featuredResults.length || results.length) && (
                  <TextLink
                    {...searchPageLinkWithQuery}
                    name={interpolateContent(
                      searchPageLinkWithQuery.name,
                      searchTerm
                    )}
                    onClick={() => {
                      overlayDispatch(close());
                      pushSearchSelect();
                    }}
                    showIcon
                  />
                )}
              </div>

              {!featuredResults.length && !results.length && !isSearching && (
                <NoDataBlock
                  heading={labels.noResultsTitleLabel}
                  message={labels.noResultsBodyLabel}
                />
              )}

              <ul className={(!isSearching && results.length > 0) ? "grid grid-cols-1 md:grid-cols-2 gap-4" : ""}>
                {!isSearching && results.length > 0 && (
                  [...featuredResults, ...results]
                    .filter(
                      (
                        result: ProductLineItemType,
                        index: number,
                        self: ProductLineItemType[]
                      ) => {
                        const productIds = self.map(
                          (item) => item.product.guid
                        );

                        return (
                          productIds.indexOf(result.product.guid) === index
                        );
                      }
                    )
                    .slice(0, 6)
                    .map((result: ProductLineItemType) => (
                      <li key={result.product.guid}>
                        <ProductCard
                          basketItem={result}
                          featured={
                            result.product.properties.featuredProduct === '1'
                          }
                          horizontal
                          labels={labels}
                          onClick={() => {
                            overlayDispatch(close());
                            pushSearchSelect();
                          }}
                          onTechDataClick={() => pushSearchDownloadTechSheet()}
                          specialPrice={
                            isLoggedIn &&
                            result.product.properties.internalRef in
                              specialPrices
                              ? specialPrices[
                                  result.product.properties.internalRef
                                ]
                              : null
                          }
                        />
                      </li>
                    ))
                )}
                {isSearching && <HeaderLoadingList count={6} isSearching={isSearching} />}
              </ul>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 xl:grid-cols-1 xl:gap-0">
              {!!categoryResults.length && (
                <div className="border-b md:border-b-0 md:border-r xl:border-b xl:border-r-0 border-grey-100 h-full flex flex-col space-y-5 py-8 lg:py-12 xl:pl-12 xl:py-12">
                  <h6 className="page-heading-six">
                    {labels.categoriesHeader}
                  </h6>

                  <ul>
                    {categoryResults.map(
                      (category: CategoryType, index: number) => (
                        <li key={category.name}>
                          <Link href={category.url} passHref>
                            <a
                              className="hover:bg-light-blue flex items-center justify-between -ml-6 pl-6 py-2 rounded-r-lg md:rounded-l-lg md:rounded-r-none"
                              href="replace"
                              onBlur={() => setHoveringCategory(null)}
                              onClick={() => {
                                overlayDispatch(close());
                                pushSearchSelect();
                              }}
                              onFocus={() => setHoveringCategory(index)}
                              onMouseLeave={() => setHoveringCategory(null)}
                              onMouseOver={() => setHoveringCategory(index)}
                            >
                              <RichText>
                                {highlightSearchTerm(category.name)}
                              </RichText>

                              <IcomoonIcon
                                className="mt-2px mr-6"
                                color={
                                  index === hoveringCategory
                                    ? 'french-blue'
                                    : 'grey-300'
                                }
                                name="chevron-right"
                                size="sm"
                              />
                            </a>
                          </Link>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}

              {!!brandResults.length && (
                <div className="flex flex-col space-y-5 py-8 md:pl-8 lg:py-12 xl:pl-12 xl:py-12">
                  <h6 className="page-heading-six">{labels.brandsHeader}</h6>

                  <ul>
                    {brandResults.map((brand: BrandType, index: number) => (
                      <li key={brand.name}>
                        <Link href={brand.link.url} passHref>
                          <a
                            className="hover:bg-light-blue flex items-center justify-between -ml-6 pl-6 py-2  rounded-r-lg md:rounded-l-lg md:rounded-r-none"
                            href="replace"
                            onBlur={() => setHoveringBrand(null)}
                            onClick={() => {
                              overlayDispatch(close());
                              pushSearchSelect();
                            }}
                            onFocus={() => setHoveringBrand(index)}
                            onMouseLeave={() => setHoveringBrand(null)}
                            onMouseOver={() => setHoveringBrand(index)}
                          >
                            <RichText>
                              {highlightSearchTerm(brand.name)}
                            </RichText>

                            <IcomoonIcon
                              className="mt-2px mr-6"
                              color={
                                index === hoveringBrand
                                  ? 'french-blue'
                                  : 'grey-300'
                              }
                              name="chevron-right"
                              size="sm"
                            />
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </Layout>
      </motion.div>
    </nav>
  );
};

export default HeaderSearchDropdown;
