import { motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import useWindowSize from '../../../hooks/use-window-size/use-window-size';
import getTailwindConfig from '../../../helpers/get-tailwind-config';

interface Props {
  readonly children: ReactNode;
  readonly className?: string;
  readonly threshold?: string;
  readonly translateY?: string;
}

const AnimationFadeIn: FC<Props> = ({
  children,
  className,
  threshold,
  translateY,
}: Props) => {
  const { width: windowWidth } = useWindowSize();
  const { screens } = getTailwindConfig().theme;

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin:
      windowWidth > parseInt(screens.md, 10)
        ? `${threshold} 0px`
        : `${parseInt(threshold.replace('px', ''), 10) / 2}px 0px`,
    initialInView: true,
  });

  const variants = {
    inView: { opacity: 1, y: 0 },
    notInView: {
      opacity: 0,
      y:
        windowWidth > parseInt(screens.md, 10)
          ? translateY
          : `${parseInt(translateY.replace('px', ''), 10) / 2}px`,
    },
  };

  return (
    <motion.section
      animate={inView ? 'inView' : 'notInView'}
      className={className}
      initial="inView"
      ref={ref}
      transition={{
        damping: 20,
        duration: 0.5,
        mass: 1.2,
        stiffness: 80,
        type: 'spring',
      }}
      variants={variants}
    >
      {children}
    </motion.section>
  );
};

AnimationFadeIn.defaultProps = {
  className: '',
  threshold: '-200px',
  translateY: '40px',
};

export default AnimationFadeIn;
