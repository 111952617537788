import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useState, useContext } from 'react';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { CategoryType } from '../../../models/category-type';
import { ContentPageHeaderNavItemType } from '../../../models/content-page-header-nav-item-type';
import { ContentPageHeaderNavType } from '../../../models/content-page-header-nav-type';
import { ContentPageHeaderProductNavType } from '../../../models/content-page-header-product-nav-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { LinkType } from '../../../models/link-type';
import HeaderMobileMenuLink from '../../atoms/header-mobile-menu-link/header-mobile-menu-link';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import Modal from '../modal/modal';

interface Props extends ContentPageHeaderNavType {
  readonly contactPageLink: LinkType;
}

const HeaderMobileMenu: FC<Props> = ({
  contactPageLink,
  navigationData,
  productNavigationData,
}: Props) => {
  const [selectedNavItem, setSelectedNavItem] = useState<
    ContentPageHeaderNavItemType | CategoryType[]
  >();
  const [selectedLink, setSelectedLink] = useState<
    ContentPageHeaderNavItemType | CategoryType
  >();
  const [selectedSecondaryLink, setSelectedSecondaryLink] = useState<
    ContentPageHeaderNavItemType | CategoryType
  >();
  const [page, setPage] = useState<number>(0);
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  const contactNavItem = {
    childNavigationItems: [],
    link: contactPageLink,
    navigationCtas: [],
  } as ContentPageHeaderNavItemType;

  const animate = { opacity: 1, y: 0 };
  const exit = { opacity: 0, y: -20 };
  const initial = { opacity: 0, y: 20 };
  const transition = {
    duration: 0.3,
  };

  const handleTopLevelNavClick = (
    link: ContentPageHeaderNavItemType | CategoryType[]
  ): void => {
    setSelectedNavItem(link);

    pushToDataLayer(GtmEventNameEnum.MainNav);
  };

  return (
    <Modal
      mobileMenu
      onClose={() => pushToDataLayer(GtmEventNameEnum.MobileNavClose)}
    >
      <motion.button
        animate={page > 0 ? 'isButtonVisible' : 'buttonNotVisible'}
        className={
          `${selectedNavItem ? 'flex' : 'hidden'} ` +
          'mobile-menu-swiper-navigation-prev absolute flex items-center focus:outline-none px-4 py-2 top-3'
        }
        onClick={() => {
          if (selectedSecondaryLink) {
            setSelectedSecondaryLink(null);

            pushToDataLayer(GtmEventNameEnum.NavSubMenuBack, {
              title:
                (selectedSecondaryLink as ContentPageHeaderNavItemType)?.link
                  ?.name || (selectedSecondaryLink as CategoryType)?.name,
            });

            return;
          }

          if (selectedLink) {
            setSelectedLink(null);

            pushToDataLayer(GtmEventNameEnum.NavSubMenuBack, {
              title:
                (selectedLink as ContentPageHeaderNavItemType)?.link?.name ||
                (selectedLink as CategoryType)?.name,
            });

            return;
          }

          setSelectedNavItem(null);

          pushToDataLayer(GtmEventNameEnum.NavSubMenuBack, {
            title:
              (selectedNavItem as ContentPageHeaderNavItemType)?.link?.name ||
              'Products',
          });
        }}
        transition={transition}
        type="button"
        variants={{
          isButtonVisible: { opacity: 1 },
          buttonNotVisible: { opacity: 0 },
        }}
      >
        <IcomoonIcon
          className="-mb-1px"
          color="grey-600"
          name="chevron-left"
          size="sm"
        />
        <span className="text-grey-600 ml-2">Back</span>
      </motion.button>

      <nav className="h-full">
        <AnimatePresence onExitComplete={() => setPage(1)}>
          {!selectedNavItem && page === 0 && (
            <ul className="flex flex-col justify-center mt-4">
              
              {productNavigationData && <motion.li
                animate={animate}
                exit={exit}
                initial={initial}
                transition={transition}
              >
                <HeaderMobileMenuLink
                  link={productNavigationData}
                  onClick={(link: ContentPageHeaderProductNavType) =>
                    handleTopLevelNavClick(link.categoryItems)
                  }
                />
              </motion.li>}

              {navigationData.map(
                (navItem: ContentPageHeaderNavItemType, index: number) => (
                  <motion.li
                    key={navItem.link.url}
                    animate={animate}
                    exit={exit}
                    initial={initial}
                    transition={{ ...transition, delay: (index + 1) * 0.05 }}
                  >
                    <HeaderMobileMenuLink
                      link={navItem}
                      onClick={(link: ContentPageHeaderNavItemType) =>
                        handleTopLevelNavClick(link)
                      }
                    />
                  </motion.li>
                )
              )}

              <motion.li
                animate={animate}
                exit={exit}
                initial={initial}
                transition={{
                  ...transition,
                  delay: (navigationData.length + 1) * 0.05,
                }}
              >
                <HeaderMobileMenuLink
                  isContactLink
                  link={contactNavItem}
                  onClick={(link: ContentPageHeaderNavItemType) => {
                    setSelectedNavItem(link);

                    pushToDataLayer(GtmEventNameEnum.MainNav);
                    pushToDataLayer(GtmEventNameEnum.NavContact);
                  }}
                />
              </motion.li>
            </ul>
          )}
        </AnimatePresence>

        <AnimatePresence onExitComplete={() => setPage(selectedLink ? 2 : 0)}>
          {selectedNavItem && !selectedLink && page === 1 && (
            <>
              <motion.h3
                animate={animate}
                className="border-b border-grey-100 mt-4 mx-4 pb-4 page-heading-three"
                exit={exit}
                initial={initial}
                transition={transition}
              >
                {(selectedNavItem as ContentPageHeaderNavItemType)?.link
                  ?.name || 'Products'}
              </motion.h3>

              <ul className="flex flex-col justify-center mt-4">
                {(
                  (selectedNavItem as ContentPageHeaderNavItemType)
                    ?.childNavigationItems ||
                  (selectedNavItem as CategoryType[])
                ).map(
                  (
                    navItem: ContentPageHeaderNavItemType | CategoryType,
                    index: number
                  ) => (
                    <motion.li
                      key={
                        (navItem as ContentPageHeaderNavItemType)?.link?.url ||
                        (navItem as CategoryType)?.url
                      }
                      animate={animate}
                      className="overflow-y-hidden"
                      exit={exit}
                      initial={initial}
                      transition={{ ...transition, delay: (index + 1) * 0.05 }}
                    >
                      <HeaderMobileMenuLink
                        link={navItem}
                        onClick={(
                          link: ContentPageHeaderNavItemType | CategoryType
                        ) => {
                          setSelectedLink(link);

                          pushToDataLayer(GtmEventNameEnum.NavSubMenu);
                        }}
                      />
                    </motion.li>
                  )
                )}
              </ul>
            </>
          )}
        </AnimatePresence>

        <AnimatePresence
          onExitComplete={() =>
            setPage(selectedLink && selectedSecondaryLink ? 3 : 1)
          }
        >
          {selectedLink && !selectedSecondaryLink && page === 2 && (
            <>
              <motion.h3
                animate={animate}
                className="border-b border-grey-100 mt-4 mx-4 pb-4 page-heading-three"
                exit={exit}
                initial={initial}
                transition={transition}
              >
                {(selectedLink as ContentPageHeaderNavItemType)?.link?.name ||
                  (selectedLink as CategoryType)?.name}
              </motion.h3>

              <ul className="flex flex-col justify-center mt-4">
                {(
                  (selectedLink as ContentPageHeaderNavItemType)
                    ?.childNavigationItems ||
                  (selectedLink as CategoryType)?.childCategoryItems
                ).map(
                  (
                    navItem: ContentPageHeaderNavItemType | CategoryType,
                    index: number
                  ) => (
                    <motion.li
                      key={
                        (navItem as ContentPageHeaderNavItemType)?.link?.url ||
                        (navItem as CategoryType)?.url
                      }
                      animate={animate}
                      className="overflow-y-hidden"
                      exit={exit}
                      initial={initial}
                      transition={{ ...transition, delay: (index + 1) * 0.05 }}
                    >
                      <HeaderMobileMenuLink
                        link={navItem}
                        onClick={(
                          link: ContentPageHeaderNavItemType | CategoryType
                        ) => {
                          setSelectedSecondaryLink(link);

                          pushToDataLayer(GtmEventNameEnum.NavSubMenu);
                        }}
                      />
                    </motion.li>
                  )
                )}
              </ul>
            </>
          )}
        </AnimatePresence>

        <AnimatePresence onExitComplete={() => setPage(2)}>
          {selectedSecondaryLink && page === 3 && (
            <>
              <motion.h3
                animate={animate}
                className="border-b border-grey-100 mt-4 mx-4 pb-4 page-heading-three"
                exit={exit}
                initial={initial}
                transition={transition}
              >
                {(selectedSecondaryLink as ContentPageHeaderNavItemType)?.link
                  ?.name || (selectedSecondaryLink as CategoryType)?.name}
              </motion.h3>

              <ul className="flex flex-col justify-center mt-4">
                {(
                  (selectedSecondaryLink as ContentPageHeaderNavItemType)
                    ?.childNavigationItems ||
                  (selectedSecondaryLink as CategoryType)?.childCategoryItems
                ).map(
                  (
                    navItem: ContentPageHeaderNavItemType | CategoryType,
                    index: number
                  ) => (
                    <motion.li
                      key={
                        (navItem as ContentPageHeaderNavItemType)?.link?.url ||
                        (navItem as CategoryType)?.url
                      }
                      animate={animate}
                      className="overflow-y-hidden"
                      exit={exit}
                      initial={initial}
                      transition={{ ...transition, delay: (index + 1) * 0.05 }}
                    >
                      <HeaderMobileMenuLink link={navItem} onClick={null} />
                    </motion.li>
                  )
                )}
              </ul>
            </>
          )}
        </AnimatePresence>
      </nav>
    </Modal>
  );
};

export default HeaderMobileMenu;
