import { ReactNode } from 'react';
import Action from '../../../types/action';
import OverlayActions from './overlay.actions';

export default function push(component: ReactNode): Action<OverlayActions> {
  return {
    type: OverlayActions.Push,
    payload: component,
  };
}
