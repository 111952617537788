import CultureCodeEnum from '../../../../models/culture-code-enum';
import { ProductBasketLineItemType } from '../../../../models/product-basket-line-item-type';
import { ProductLineItemType } from '../../../../models/product-line-item-type';
import { SpecialPricingResponseType } from '../../../../models/special-pricing-response-type';
import { SpecialPricingType } from '../../../../models/special-pricing-type';
import client from '../../client';

const pricingRequest = async (
  items: ProductLineItemType[]
): Promise<SpecialPricingType[]> => {
  if (!items) {
    return;
  }

  const { data } = await client.post<SpecialPricingResponseType>(
    '/pricing/request',
    [
      ...items.map((item: ProductBasketLineItemType) => ({
        productId: parseInt(item.product.properties.internalRef || '0', 10),
        listPrice: item.listPrice,
        pdgDiscountNumber: parseInt(
          item.product.properties.discountGroupIcon,
          10
        ),
      })),
    ],
    {
      params: {
        cultureCode: CultureCodeEnum.EnGb,
        domain: process.env.NEXT_PUBLIC_DOMAIN,
      },
    }
  );

  return data.prices.map((price: SpecialPricingType) => ({
    ...price,
    priceFormatted: `${data.currencySymbol}${price.price.toFixed(2)}`,
  }));
};

export default pricingRequest;
