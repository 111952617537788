import Image from 'next/image';
import Link from 'next/link';
import React, { FC, useContext, useState } from 'react';
import useNextImageLoader from '../../../hooks/use-next-image-loader/use-next-image-loader';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { ContentPageFooterType } from '../../../models/content-page-footer-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { LinkType } from '../../../models/link-type';
import { SocialMediaLinkType } from '../../../models/social-media-link-type';
import IcomoonIconButton from '../../atoms/icomoon-icon-button/icomoon-icon-button';
import TextLink from '../../atoms/text-link/text-link';
import Layout from '../../layout/layout';
import useIsLoggedIn from '../../providers/user/hooks/use-is-logged-in';
import useLogout from '../../providers/user/hooks/use-logout.hook';
import styles from './button-onetrust.module.scss';

interface Props extends ContentPageFooterType {
  readonly isAuthenticatedPage: boolean;
}

const Footer: FC<Props> = ({
  callToActions,
  footerColumns,
  isAuthenticatedPage,
  loggedInHeading,
  loggedInLinkOne,
  loggedInLinkTwo,
  loggedInSubHeading,
  loggedOutHeading,
  loggedOutLinkOne,
  loggedOutLinkTwo,
  loggedOutSubHeading,
  socialLinks,
  subFooter,
  websiteLogo,
}) => {
  const isLoggedIn = useIsLoggedIn();
  // const router = useRouter();

  const [isLogoutPending, setIsLogoutPending] = useState(false);
  const logout = useLogout();

  const handleLogout = async () => {
    setIsLogoutPending(true);

    try {
      await logout();
    } finally {
      setIsLogoutPending(false);
    }
  };

  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  const toggleCookiePreferences = () => {
    if (
      typeof window !== 'undefined' &&
      !!window?.OneTrust?.ToggleInfoDisplay
    ) {
      window.OneTrust.ToggleInfoDisplay();
    }
  };

  const mergedCallToActions: ContentPageFooterType['callToActions'] = [
    ...callToActions,
    isLoggedIn
      ? {
          heading: loggedInHeading,
          text: loggedInSubHeading,
          links: [loggedInLinkOne, loggedInLinkTwo],
        }
      : {
          heading: loggedOutHeading,
          text: loggedOutSubHeading,
          links: [loggedOutLinkOne, loggedOutLinkTwo],
        },
  ];

  return (
    <footer className="bg-grey-50">
      <Layout>
        <div className="xl:py-8 xl:flex xl:flex-row xl:flex-wrap">
          <div className="flex flex-col py-8 xl:py-0 xl:pr-8 md:flex-row items-start xl:w-8/12">
            <div className="mb-8 w-full md:w-2/12 md:mb-0 md:mr-8 flex justify-center md:flex-col xl:w-6/12">
              {socialLinks?.length && (
                <>
                  {socialLinks.map((link: SocialMediaLinkType) => (
                    <IcomoonIconButton
                      className="m-2"
                      color="grey-400"
                      key={link.icon}
                      name={link.icon}
                      onClick={() =>
                        pushToDataLayer(GtmEventNameEnum.FooterSocial, {
                          socialChannel: link.icon,
                        })
                      }
                      size="lg"
                      target="_blank"
                      url={link.url}
                      ariaLabel={link.icon || 'Icon link'}
                    />
                  ))}
                </>
              )}
            </div>

            <div className="md:6/12 flex flex-col md:flex-row">
              {footerColumns.map((column) => (
                <div
                  key={column.heading}
                  className="w-full mb-8 mt-4 md:w-6/12 text-center md:text-left px-4"
                >
                  <h6 className="page-heading-six">{column.heading}</h6>

                  {column.subHeading && (
                    <p className="text-body text-grey-600 mt-4">
                      {column.subHeading}
                    </p>
                  )}

                  {column.navigation?.length && (
                    <ol className="flex flex-col mt-4">
                      {column?.navigation
                        .filter((link) => !!link)
                        .map((link: LinkType) => (
                          <li key={link.name} className="pr-4 mb-4">
                            <Link href={link.url} passHref>
                              <a
                                className="hover:underline"
                                href="replace"
                                onClick={() =>
                                  pushToDataLayer(GtmEventNameEnum.FooterNav)
                                }
                                target={link.target}
                              >
                                {link.name}
                              </a>
                            </Link>
                          </li>
                        ))}
                    </ol>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="grid gap-5 md:grid-cols-2 pb-8 xl:pb-0 xl:grid-cols-1 xl:w-4/12">
            {mergedCallToActions.map((cta) => (
              <div key={cta.heading} className="text-left group-hover:mb-4">
                <div className="bg-white flex flex-col h-full rounded-lg p-4">
                  <p className="text-md mb-4">{cta.heading}</p>

                  {cta.text && (
                    <p className="h-full text-grey-600 mb-4">{cta.text}</p>
                  )}

                  <div className="flex flex-row">
                    {cta.links
                      .filter((link) => !!link)
                      .map((link: LinkType) =>
                        link.url.includes('logout') ||
                        link.url.includes('Logout') ? (
                          <TextLink
                            className="mr-2"
                            key={link.name}
                            name={link.name}
                            showIcon
                            loading={isLogoutPending}
                            onClick={() => {
                              handleLogout();

                              pushToDataLayer(GtmEventNameEnum.FooterNavCard, {
                                cardTitle: cta.heading,
                              });

                              if (isAuthenticatedPage) {
                                if (
                                  window.location.search.indexOf('debug') >= 0
                                ) {
                                  // eslint-disable-next-line no-debugger
                                  debugger;
                                }
                              }
                            }}
                          />
                        ) : (
                          <TextLink
                            className="mr-2"
                            key={link.name}
                            name={link.name}
                            onClick={() =>
                              pushToDataLayer(GtmEventNameEnum.FooterNavCard, {
                                cardTitle: cta.heading,
                              })
                            }
                            showIcon
                            url={link.url}
                            ariaLabel={cta.heading}
                          />
                        )
                      )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-8 xl:-mt-20 text-center md:text-left">
          <Image
            height={51}
            loader={useNextImageLoader}
            src={websiteLogo}
            width={180}
            alt="Logo image"
          />
        </div>
      </Layout>

      <div className="w-full bg-grey-100">
        <Layout>
          <div className="flex flex-col md:flex-row items-center py-8">
            {subFooter.copyrightNotice && (
              <p
                className="text-grey-600"
                dangerouslySetInnerHTML={{
                  __html: `&copy; ${subFooter.copyrightNotice}`,
                }}
              />
            )}

            {subFooter.subFooterLinks?.length && (
              <nav>
                <ol className="flex flex-row md:ml-4">
                  {subFooter.subFooterLinks
                    .filter((link) => !!link)
                    .map((link: LinkType) => (
                      <li key={link.name} className="pr-4">
                        <Link href={link.url} passHref>
                          <a
                            className="hover:text-french-blue"
                            href="replace"
                            onClick={() =>
                              pushToDataLayer(GtmEventNameEnum.FooterNav)
                            }
                            target={link.target}
                          >
                            {link.name}
                          </a>
                        </Link>
                      </li>
                    ))}
                  <button
                    type="button"
                    onClick={toggleCookiePreferences}
                    className={styles.buttonOneTrust}
                  >
                    Cookie Settings
                  </button>
                </ol>
              </nav>
            )}
          </div>
        </Layout>
      </div>
    </footer>
  );
};

export default Footer;
