import React, { FC, useState } from 'react';
import AnimationToggleRotate from '../../animations/animation-toggle-rotate/animation-toggle-rotate';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';

interface Props {
  readonly className?: string;
  readonly label: string;
  readonly onSort?: (direction: number) => void;
}

const TableHeading: FC<Props> = ({ className, label, onSort }: Props) => {
  const [sortAscending, setSortAscending] = useState<boolean>(false);

  const handleSort = (): void => {
    setSortAscending(!sortAscending);
    onSort(sortAscending ? 1 : -1);
  };

  return (
    <th className={`${className} pb-3 px-5 text-left`}>
      {!onSort && <span className="text-sm text-grey-600">{label}</span>}

      {onSort && (
        <button
          className="flex items-center focus:outline-none"
          onClick={() => handleSort()}
          type="button"
        >
          <span className="text-sm text-french-blue">{label}</span>

          <div className="-mb-2px ml-2">
            <AnimationToggleRotate isRotated={sortAscending}>
              <IcomoonIcon color="french-blue" name="chevron-down" size="sm" />
            </AnimationToggleRotate>
          </div>
        </button>
      )}
    </th>
  );
};

TableHeading.defaultProps = {
  className: '',
  onSort: null,
};

export default TableHeading;
