const truncate = (item: string, limit = 60): string => {
  if (item.length <= limit) {
    return item;
  }

  const truncated = item.substring(0, limit);

  return `${truncated.substring(0, truncated.lastIndexOf(' '))}...`;
};

export default truncate;
