import { AnimatePresence, motion } from 'framer-motion';
import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useMutation } from 'react-query';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { BasketAddProductErrorLabelsType } from '../../../models/basket-add-product-error-labels-type';
import { BasketClearQuoteLabelsType } from '../../../models/basket-clear-quote-labels-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import PlacementEnum from '../../../models/placement-enum';
import { ProductType } from '../../../models/product-type';
import ProductTypeEnum from '../../../models/product-type-enum';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import FieldControl from '../../atoms/field-control/field-control';
import FieldText from '../../atoms/field-text/field-text';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import { BasketStateContext } from '../../providers/basket/basket.provider';
import useAddToBasket from '../../providers/basket/hooks/use-add-to-basket';
import TextLink from '../../atoms/text-link/text-link';
import { ProductBasketLineItemType } from '../../../models/product-basket-line-item-type';
import open from '../../providers/overlay/open.overlay.actions';
import BasketLeadTimes from '../basket-lead-times/basket-lead-times';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import { CheckoutBasketTableLabelsType } from '../../../models/checkout-basket-table-labels-type';

interface Props {
  readonly addProductErrorLabels: BasketAddProductErrorLabelsType;
  readonly addToBasketLabel: string;
  readonly backgroundColor?: string;
  readonly clearQuoteLabels: BasketClearQuoteLabelsType;
  readonly onClick?: () => void;
  readonly paddingX?: 'full' | 'half';
  readonly product: ProductType;
  readonly stockQuantity?: number;
  readonly alwaysShowStockAmount: boolean;
  readonly hideStockAmount?: boolean;
}

// TODO: half ot this needs to be elevated outside this component
const AddToBasketButton: FC<Props> = ({
  addProductErrorLabels,
  addToBasketLabel,
  backgroundColor,
  clearQuoteLabels,
  paddingX,
  product,
  onClick,
  stockQuantity,
  alwaysShowStockAmount,
  hideStockAmount,
}: Props) => {
  const addToBasket = useAddToBasket(addProductErrorLabels, clearQuoteLabels);
  const overlayDispatch = useContext(OverlayDispatchContext);
  const [quantity, setQuantity] = useState<number>(1);
  const [itemAdded, setItemAdded] = useState<boolean>(false);
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const basketState = useContext(BasketStateContext);
  const [basketAmountSatisfied, basketAmountBackorder] = useMemo(() => {
    const item = basketState.basket?.lineItems?.find(
      (i) => i.productNumber === product.properties.internalRef
    );
    return item
      ? [
          item.quantitySatisfied,
          item.quantitySatisfiedSlot1 +
            item.quantitySatisfiedSlot2 +
            item.quantitySatisfiedSlot3,
        ]
      : [0, 0];
  }, [basketState.basket]);

  const showStockAmount =
    alwaysShowStockAmount ||
    !!basketAmountBackorder ||
    quantity > stockQuantity - basketAmountSatisfied;

  const [showBackorderAmount, setShowBackorderAmount] =
    useState<boolean>(false);

  useEffect(() => {
    const showBackorderAmountBool =
      !!basketAmountBackorder ||
      (quantity > stockQuantity - basketAmountSatisfied && quantity > 1);

    setShowBackorderAmount(showBackorderAmountBool);
  }, [basketAmountBackorder, quantity, basketAmountSatisfied]);

  useEffect(() => {
    setShowBackorderAmount(false);
  }, [product]);

  const { mutate, isLoading, isSuccess } = useMutation(
    (request: { variantSku: string; quantity: number }) =>
      addToBasket(request.variantSku, request.quantity)
  );

  // TODO: get the labels from backend
  const checkoutLabels = {
    checkLeadTimesLabel: 'Check lead times',
    leadTimesPopupHeading: 'Lead times',
    leadTimesPopupLeadTimeColumnLabel: 'Lead Time',
    leadTimesPopupQuantityColumnLabel: 'Quantity',
  } as CheckoutBasketTableLabelsType;

  const showCheckLeadTimesLink =
    !!basketAmountSatisfied || !!basketAmountBackorder;

  const openLeadTimesModal = useCallback((): void => {
    overlayDispatch(
      open(
        <BasketLeadTimes
          lineItem={
            {
              productNumber: product.properties.internalRef,
              product,
            } as ProductBasketLineItemType
          }
          checkoutLabels={checkoutLabels}
          requiredQuantity={basketAmountSatisfied + basketAmountBackorder}
        />
      )
    );
  }, [product, basketAmountSatisfied, basketAmountBackorder, quantity]);

  useEffect(() => {
    if (isSuccess) {
      setItemAdded(true);

      pushToDataLayer(GtmEventNameEnum.AddToCart, {
        ecommerce: {
          add: {
            products: [
              {
                productImage: product.properties.webImageLarge1425,
                productName: product.name,
                productCode: product.variantSku,
                masterCode: product.sku,
                itemPrice: product.prices[0].listPriceFormatted,
                listPrice: product.prices[0].listPriceFormatted,
                quantity,
              },
            ],
          },
        },
        basketValue: basketState.total,
        featuredProduct: product.properties.featuredProduct === '1',
        placement: PlacementEnum.ProductDetails,
        productType: ProductTypeEnum.Website,
      });

      setQuantity(1);

      setTimeout(() => {
        setItemAdded(false);
      }, 2000);
    }
  }, [isSuccess]);

  const addItem = async (): Promise<void> => {
    onClick?.();
    mutate({ variantSku: product.variantSku, quantity });
  };

  return (
    <div>
      {!hideStockAmount && (
        <div className="mb-2.5">
          <>
            {stockQuantity > 0 ? (
              <div className="flex items-center">
                <IcomoonIcon
                  className="mr-1.5"
                  color="check-green"
                  name="check"
                  size="xs"
                />
                <span className="text-xs text-check-green">
                  {`${
                    showStockAmount ? `${stockQuantity} ` : ''
                  }Immediately Available`}
                </span>
              </div>
            ) : (
              <div className="flex items-center">
                <IcomoonIcon
                  className="mr-1.5"
                  color="check-green"
                  name="alert-circle"
                  size="xs"
                />
                <span className="text-xs text-check-green">
                  Available to Order
                </span>
              </div>
            )}
            {showBackorderAmount && (
              <div className="flex items-center">
                <IcomoonIcon
                  className="mr-1.5"
                  color="alert-orange"
                  name="alert-circle"
                  size="xs"
                />
                <span className="text-xs text-alert-orange mr-1">
                  {`${
                    basketAmountBackorder
                      ? basketAmountBackorder + (quantity > 1 ? quantity : 0)
                      : quantity - (stockQuantity - basketAmountSatisfied)
                  } Back order${showCheckLeadTimesLink ? ', ' : ''}`}
                </span>
                {showCheckLeadTimesLink && (
                  <TextLink
                    name={checkoutLabels.checkLeadTimesLabel}
                    textSize="xs"
                    onClick={openLeadTimesModal}
                    textColor="alert-orange"
                  />
                )}
              </div>
            )}
          </>
        </div>
      )}
      <div className="flex space-x-4 justify-center items-end">
        <FieldControl label="Qty">
          <FieldText
            type="number"
            backgroundColor={backgroundColor}
            min={1}
            step={1}
            value={quantity}
            onChange={(value: string) => setQuantity(parseInt(value, 10))}
            disabled={isLoading}
            alwaysShowRing
          />
        </FieldControl>

        <div className="relative">
          <motion.div
            animate={itemAdded ? 'itemAdded' : 'itemNotAdded'}
            variants={{
              itemAdded: { opacity: 0, scale: 0 },
              itemNotAdded: { opacity: 1, scale: 1 },
            }}
          >
            <ButtonPrimary
              className={isLoading ? 'animate-pulse' : ''}
              disabled={isLoading}
              name={addToBasketLabel}
              paddingX={paddingX}
              onClick={addItem}
            />
          </motion.div>

          <AnimatePresence>
            {itemAdded && (
              <motion.div
                className="absolute inset-0 flex items-center justify-center rounded-full w-full px-12 py-4"
                initial={{ opacity: 0, scale: 0.75 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
              >
                <div className="flex items-center">
                  <IcomoonIcon
                    className="mr-2"
                    color="french-blue"
                    name="check"
                    size="md"
                  />
                  <span className="page-heading-six text-french-blue whitespace-nowrap">
                    Added to basket
                  </span>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

AddToBasketButton.defaultProps = {
  backgroundColor: 'white',
  paddingX: 'half',
  onClick: null,
  stockQuantity: 0,
  hideStockAmount: false,
};

export default AddToBasketButton;
