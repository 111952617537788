import Link from 'next/link';
import React, { FC } from 'react';
import { ProductBasketLineItemType } from '../../../models/product-basket-line-item-type';

interface Props {
  readonly lineItem: ProductBasketLineItemType;
}

const ProductOverview: FC<Props> = ({ lineItem }) => (
  <div>
    <span className="text-sm block">{lineItem.product.name}</span>
    {lineItem.isCatalogueProduct ? (
      <Link href={lineItem.product.url} passHref>
        <a className="text-sm text-french-blue hover:underline" href="replace">
          {lineItem.product.variantSku}
        </a>
      </Link>
    ) : (
      <span className="text-sm">{lineItem.product.variantSku}</span>
    )}
    {lineItem.product.properties.manufacturersCode &&
      lineItem.product.properties.manufacturersCode !==
        lineItem.product.variantSku && (
        <span className="text-sm block text-grey-600">
          {lineItem.product.properties.manufacturersCode}
        </span>
      )}
  </div>
);

export default ProductOverview;
