import CultureCodeEnum from '../../../../../../models/culture-code-enum';
import client from '../../../../client';

const orderMemberQuoteDeselect = async (
  quoteNumber: number,
  sessionId: string
): Promise<unknown> => {
  const { data } = await client.post<unknown>(
    '/order/member/quote/deselect',
    {
      quoteNumber,
      sessionId,
      provider: 0,
      accountNumber: 0,
    },
    {
      params: {
        cultureCode: CultureCodeEnum.EnGb,
        domain: process.env.NEXT_PUBLIC_DOMAIN,
      },
    }
  );

  return data;
};

export default orderMemberQuoteDeselect;
